import { DataTable, DataTableBody, DataTableHead, DataTableItem, DataTableRow } from "../../../../components/Component";
import { format } from "date-fns";
import { Badge } from "reactstrap";

const STATUSES = {
  OPEN: "Open",
  IN_PROGRESS: "In Progress",
  READY_FOR_APPROVAL: "Ready For Approval",
  COMPLETED: "Completed",
};
const statusObj = {
  OPEN: "danger",
  IN_PROGRESS: "success",
  READY_FOR_APPROVAL: "warning",
  COMPLETED: "info",
};

const ParentTask = ({ currentParentTask, taskPlant, onClick }) => {
  return (
    <DataTable className="card-stretch">
      <DataTableBody compact className={"mt-2"}>
        <DataTableHead>
          <DataTableRow>
            <span className="sub-text">Ticket Name</span>
          </DataTableRow>
          <DataTableRow size="md">
            <span className="sub-text">Code</span>
          </DataTableRow>
          <DataTableRow size="md">
            <span className="sub-text">Status</span>
          </DataTableRow>
          <DataTableRow size="sm">
            <span className="sub-text">Start Date</span>
          </DataTableRow>
          <DataTableRow size="md">
            <span className="sub-text">Resolved Date</span>
          </DataTableRow>
          <DataTableRow size="md">
            <span className="sub-text">Plant Name</span>
          </DataTableRow>
        </DataTableHead>
        <DataTableItem>
          <DataTableRow>
            <div
              onClick={() => {
                onClick();
              }}
              className="user-card dropdown-toggle"
            >
              <div className="user-name">
                <span className="tb-lead">{currentParentTask?.title}</span>
              </div>
            </div>
          </DataTableRow>
          <DataTableRow id="corrective-identifier" size="md">
            <span
              data-toggle="tooltip"
              title={currentParentTask?.identifier}
              style={{
                display: "inline-block",
                width: "90px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {currentParentTask?.identifier}
            </span>
          </DataTableRow>
          <DataTableRow size="md">
            <span>
              <Badge className="text-capitalize" color={statusObj[currentParentTask?.status]} pill>
                {STATUSES[currentParentTask?.status] || "-"}
              </Badge>
            </span>
          </DataTableRow>
          <DataTableRow size="sm">
            <span>
              {currentParentTask?.startedAt
                ? format(new Date(currentParentTask?.startedAt ? currentParentTask?.startedAt : null), "dd/MM/yyyy")
                : null}
            </span>
          </DataTableRow>
          <DataTableRow size="sm">
            <span>
              {" "}
              {currentParentTask?.resolvedAt
                ? format(new Date(currentParentTask?.resolvedAt ? currentParentTask.resolvedAt : null), "dd/MM/yyyy")
                : null}
            </span>
          </DataTableRow>
          <DataTableRow size="lg">
            <span>{taskPlant?.name}</span>
          </DataTableRow>
        </DataTableItem>
      </DataTableBody>
    </DataTable>
  );
};

export default ParentTask;
