import { format } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  PaginationComponent,
  Sidebar,
} from "../../../../components/Component";
import FilteredByList from "../../../../components/FilteredBy";
import Check from "../../../../components/check_roles/check";
import { confirmationSweetAlert } from "../../../../components/sweetAlert/SweetAlert";
import { errorToast, successToast } from "../../../../components/toastify/Toastify";
import ModalViewer from "../../ModalViewer";
import { HealthAndSafetyContext } from "../HealthAndSafetyProvider";
import HealthAndSafetyFormContainer from "../containers/HealthAndSafetyFormContainer";
import HealthAndSafetyViewContainer from "../containers/HealthAndSafetyViewContainer";
import ExportForm from "./ExportForm";
import HealthAndSafetyListFilter from "./HealthAndSafetyListFilter";

const HealthAndSafetyListCompact = () => {
  // REFS
  const focusSearchRef = React.useRef();

  const healthAndSafetyContext = useContext(HealthAndSafetyContext);
  const { healthAndSafetyList, pagination } = healthAndSafetyContext;
  const {
    filterParams,
    handleApplyFilter,
    loadHealthAndSafetyList,
    deleteHealthAndSafety,
    healthAndSafetyLoading,
    isFilterApplied,
    sfState,
    showFilter,
  } = healthAndSafetyContext;

  const [sm, updateSm] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [healthAndSafety, setHealthAndSafety] = useState("");
  const [view, setView] = useState("");
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [isExpotModalOpen, setIsExportModalOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const VIEW = {
    VIEW: "VIEW",
    CREATE: "CREATE",
    EDIT: "EDIT",
  };

  useEffect(() => {
    if (focusSearchRef.current) focusSearchRef.current.focus();
  });

  // Changing state value when searching name
  useEffect(() => {}, [onSearchText]);

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  const handleDeleteHealthAndSafety = async (healthAndSafetyId) => {
    deleteHealthAndSafety(healthAndSafetyId)
      .then((res) => {
        successToast({ description: "Medical Centre is deleted successfully" });
      })
      .catch((e) => {
        errorToast({ description: "Error happened while deleting Medical Centre" });
      });
  };
  const handleDeleteConfirmation = async (healthAndSafetyId) => {
    confirmationSweetAlert({
      id: healthAndSafetyId,
      handleConfirmation: handleDeleteHealthAndSafety,
    });
  };
  const currentItems = healthAndSafetyList?.items || [];

  const viewHealthAndSafety = (healthAndSafetyId) => {
    setHealthAndSafety(healthAndSafetyId);
    setView(VIEW.VIEW);
    toggleModal();
  };

  const editHealthAndSafety = (healthAndSafetyId) => {
    setHealthAndSafety(healthAndSafetyId);
    setView(VIEW.EDIT);
    setIsOpen(true);
  };

  const handleNameSort = (sortOrder, field) => {
    handleApplyFilter({ ...filterParams, sortingOrder: sortOrder, sortingField: field });
  };

  const createHealthAndSafety = () => {
    setView(VIEW.CREATE);
    setIsOpen(!isOpen);
    return <>Edit</>;
  };
  const onSuccessfulModal = () => {
    toggleModal();
    loadHealthAndSafetyList();
  };

  const getComponent = () => {
    if (view === VIEW.VIEW) {
      return (
        <HealthAndSafetyViewContainer
          healthAndSafetyId={healthAndSafety}
          setIsOpen={setIsOpen}
          editHealthAndSafety={editHealthAndSafety}
        />
      );
    } else if (view === VIEW.CREATE) {
      return <HealthAndSafetyFormContainer onSuccessfulModal={onSuccessfulModal} />;
    } else if (view === VIEW.EDIT) {
      return <HealthAndSafetyFormContainer healthAndSafetyId={healthAndSafety} onSuccessfulModal={onSuccessfulModal} />;
    }
  };

  const onSearchAction = () => {
    handleApplyFilter({ ...filterParams, searchText: onSearchText });
  };
  const toggleExportModal = () => {
    setIsExportModalOpen(!isExpotModalOpen);
  };

  const getExportComponent = () => {
    return <ExportForm onSuccessfulModal={onSuccessfulModal} filterParams={filterParams} />;
  };

  const createExport = () => {
    setIsExportModalOpen(!isExpotModalOpen);
  };

  return (
    <React.Fragment>
      {
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Medical Centre Lists
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>You have a total of {healthAndSafetyList?.pagination?.totalCount || 0} Medical Centres.</p>
              </BlockDes>
              <div className="d-flex flex-wrap">
                <FilteredByList
                  data={filterParams.selectedMedicalCenter}
                  title={"Medical Center"}
                  isNotMap={true}
                  isStr={true}
                />
                <FilteredByList data={filterParams.selectedAddress} title={"Address"} isNotMap={true} isStr={true} />
                <FilteredByList
                  data={filterParams.selectedPhoneNumber}
                  title={"Phone Number"}
                  isNotMap={true}
                  isStr={true}
                />
                <FilteredByList data={filterParams.searchText} title={"Search By"} isNotMap={true} isStr={true} />
              </div>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  id="menu-health-btn"
                  className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <Check permission={["getEntity", "manageEntity"]}>
                      <li>
                        <Button
                          id="export-hralth-btn"
                          className={`btn btn-white btn-outline-light ${
                            healthAndSafetyList?.pagination?.totalCount === 0 ? "disabled" : ""
                          }`}
                          type="export"
                          onClick={createExport}
                        >
                          <Icon name="download-cloud"> </Icon>
                          <span>Export</span>
                        </Button>
                      </li>
                    </Check>
                    <Check permission={["manageEntity"]}>
                      <li className="nk-block-tools-opt">
                        <Button
                          id="plus-health-btn"
                          color="primary"
                          className="btn-icon"
                          onClick={createHealthAndSafety}
                        >
                          <Icon name="plus"></Icon>
                        </Button>
                      </li>
                    </Check>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
      }
      <Block>
        <DataTable className="card-stretch">
          {
            <div className="card-inner position-relative card-tools-toggle ">
              <div className="card-title-group ">
                <div className="card-tools"></div>
                <div className="card-tools mr-n1 ">
                  <ul className="btn-toolbar gx-1">
                    <li>
                      <a
                        id="search-health-btn"
                        href="#search"
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggle();
                        }}
                        className="btn btn-icon search-toggle toggle-search"
                      >
                        <Icon name="search"></Icon>
                      </a>
                    </li>
                    <li className="btn-toolbar-sep"></li>
                    <div id="asset-filter-btn" tag="a" className="btn btn-icon " onClick={() => sfState.toggle()}>
                      {isFilterApplied ? <div className="dot dot-primary"></div> : null}
                      <Icon name="filter-alt"></Icon>
                    </div>
                  </ul>
                </div>
              </div>
              <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                <div className="card-body">
                  <div className="search-content">
                    <Button
                      id="cancel-search-btn"
                      className="search-back btn-icon toggle-search active"
                      onClick={() => {
                        setSearchText("");
                        handleApplyFilter({ ...filterParams, searchText: "" });
                        toggle();
                      }}
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                    <input
                      type="text"
                      className="border-transparent form-focus-none form-control"
                      placeholder="Search by hospital"
                      ref={(el) => {
                        focusSearchRef.current = !onSearch ? el : null;
                      }}
                      value={onSearchText}
                      onChange={(e) => onFilterChange(e)}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          onSearchAction();
                        }
                        if (e.keyCode === 27) {
                          setSearchText("");
                          handleApplyFilter({ ...filterParams, searchText: "" });
                          toggle();
                        }
                      }}
                    />
                    <Button className="search-submit btn-icon">
                      <Icon name="search" onClick={onSearchAction}></Icon>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          }
          {healthAndSafetyLoading ? (
            <></>
          ) : (
            <DataTableBody className={"table-body"} compact>
              <DataTableHead>
                <DataTableRow>
                  <div className="d-flex" style={{ cursor: "pointer" }}>
                    <span
                      className="sub-text pr-1"
                      onClick={() => {
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "id"
                          ? handleNameSort("DESC", "id")
                          : handleNameSort("ASC", "id");
                      }}
                    >
                      ID
                    </span>
                    <div className="d-flex flex-column justify-content-center">
                      <Icon
                        style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                        onClick={() => {
                          handleNameSort("ASC", "id");
                        }}
                        name={`${
                          filterParams.sortingOrder === "ASC" && filterParams.sortingField === "id"
                            ? "caret-up-fill "
                            : "caret-up"
                        } `}
                      ></Icon>

                      <Icon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleNameSort("DESC", "id");
                        }}
                        name={`${
                          filterParams.sortingOrder === "DESC" && filterParams.sortingField === "id"
                            ? "caret-down-fill"
                            : "caret-down"
                        }`}
                      ></Icon>
                    </div>
                  </div>
                </DataTableRow>
                <DataTableRow>
                  <div className="d-flex" style={{ cursor: "pointer" }}>
                    <span
                      className="sub-text pr-1"
                      onClick={() => {
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "name"
                          ? handleNameSort("DESC", "name")
                          : handleNameSort("ASC", "name");
                      }}
                    >
                      Hospital
                    </span>
                    <div className="d-flex flex-column justify-content-center">
                      <Icon
                        style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                        onClick={() => {
                          handleNameSort("ASC", "name");
                        }}
                        name={`${
                          filterParams.sortingOrder === "ASC" && filterParams.sortingField === "name"
                            ? "caret-up-fill "
                            : "caret-up"
                        } `}
                      ></Icon>

                      <Icon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleNameSort("DESC", "name");
                        }}
                        name={`${
                          filterParams.sortingOrder === "DESC" && filterParams.sortingField === "name"
                            ? "caret-down-fill"
                            : "caret-down"
                        }`}
                      ></Icon>
                    </div>
                  </div>
                </DataTableRow>
                <DataTableRow size="md">
                  <div className="d-flex" style={{ cursor: "pointer" }}>
                    <span
                      className="sub-text pr-1"
                      onClick={() => {
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "phone_number"
                          ? handleNameSort("DESC", "phone_number")
                          : handleNameSort("ASC", "phone_number");
                      }}
                    >
                      Phone Number
                    </span>
                    <div className="d-flex flex-column justify-content-center">
                      <Icon
                        style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                        onClick={() => {
                          handleNameSort("ASC", "phone_number");
                        }}
                        name={`${
                          filterParams.sortingOrder === "ASC" && filterParams.sortingField === "phone_number"
                            ? "caret-up-fill "
                            : "caret-up"
                        } `}
                      ></Icon>

                      <Icon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleNameSort("DESC", "phone_number");
                        }}
                        name={`${
                          filterParams.sortingOrder === "DESC" && filterParams.sortingField === "phone_number"
                            ? "caret-down-fill"
                            : "caret-down"
                        }`}
                      ></Icon>
                    </div>
                  </div>
                </DataTableRow>
                <DataTableRow size="md">
                  <div className="d-flex" style={{ cursor: "pointer" }}>
                    <span
                      className="sub-text pr-1"
                      onClick={() => {
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "address"
                          ? handleNameSort("DESC", "address")
                          : handleNameSort("ASC", "address");
                      }}
                    >
                      Address
                    </span>
                    <div className="d-flex flex-column justify-content-center">
                      <Icon
                        style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                        onClick={() => {
                          handleNameSort("ASC", "address");
                        }}
                        name={`${
                          filterParams.sortingOrder === "ASC" && filterParams.sortingField === "address"
                            ? "caret-up-fill "
                            : "caret-up"
                        } `}
                      ></Icon>

                      <Icon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleNameSort("DESC", "address");
                        }}
                        name={`${
                          filterParams.sortingOrder === "DESC" && filterParams.sortingField === "address"
                            ? "caret-down-fill"
                            : "caret-down"
                        }`}
                      ></Icon>
                    </div>
                  </div>
                </DataTableRow>
                <DataTableRow size="md">
                  <div className="d-flex" style={{ cursor: "pointer" }}>
                    <span
                      className="sub-text pr-1"
                      onClick={() => {
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "updated_at"
                          ? handleNameSort("DESC", "updated_at")
                          : handleNameSort("ASC", "updated_at");
                      }}
                    >
                      UpdatedAt
                    </span>
                    <div className="d-flex flex-column justify-content-center">
                      <Icon
                        style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                        onClick={() => {
                          handleNameSort("ASC", "updated_at");
                        }}
                        name={`${
                          filterParams.sortingOrder === "ASC" && filterParams.sortingField === "updated_at"
                            ? "caret-up-fill "
                            : "caret-up"
                        } `}
                      ></Icon>

                      <Icon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleNameSort("DESC", "updated_at");
                        }}
                        name={`${
                          filterParams.sortingOrder === "DESC" && filterParams.sortingField === "updated_at"
                            ? "caret-down-fill"
                            : "caret-down"
                        }`}
                      ></Icon>
                    </div>
                  </div>
                </DataTableRow>
                <Check permission={["manageEntity"]}>
                  <DataTableRow>{/* <span className="sub-text">Actions</span> */}</DataTableRow>
                </Check>
              </DataTableHead>
              <Sidebar toggleState={showFilter}>
                <HealthAndSafetyListFilter />
              </Sidebar>
              {/*Head*/}
              {currentItems.length > 0 ? (
                currentItems.map((item, key) => {
                  return (
                    <DataTableItem key={item.id}>
                      <DataTableRow size="md" className="d-flex justify-content-center">
                        <span className="noWrap">{item.id}</span>
                      </DataTableRow>
                      <DataTableRow>
                        <div
                          className="user-card dropdown-toggle"
                          onClick={() => {
                            viewHealthAndSafety(item.id);
                          }}
                        >
                          <div className="user-name">
                            <span
                              data-toggle="tooltip"
                              title={item.name}
                              style={{
                                width: item.name?.length > 50 ? "350px" : "",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                cursor: "pointer",
                              }}
                              className="tb-lead"
                            >
                              {item.name}
                            </span>
                          </div>
                        </div>
                      </DataTableRow>
                      <DataTableRow size="md">
                        <span className="noWrap">{item.phoneNumber}</span>
                      </DataTableRow>
                      <DataTableRow size="md">
                        <span
                          data-toggle="tooltip"
                          title={item.address}
                          style={{
                            display: "inline-block",
                            width: item.address?.length > 50 ? "350px" : "",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            cursor: "pointer",
                          }}
                        >
                          {item.address}
                        </span>
                      </DataTableRow>
                      <DataTableRow size="md">
                        <span
                          data-toggle="tooltip"
                          title={item.updated_at}
                          style={{
                            display: "inline-block",
                            width:
                              JSON.stringify(format(new Date(item?.updated_at || null), "dd/MM/yyyy HH:mm"))?.length >
                              20
                                ? "140px"
                                : "",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            cursor: "pointer",
                          }}
                        >
                          {format(new Date(item?.updated_at || null), "dd/MM/yyyy HH:mm")}
                        </span>
                      </DataTableRow>
                      <Check permission={["manageEntity"]}>
                        <DataTableRow className="d-flex justify-content-center">
                          <Button
                            onClick={() => {
                              editHealthAndSafety(item.id);
                            }}
                          >
                            {" "}
                            <Icon name="edit"></Icon>
                            <span>Edit</span>
                          </Button>
                          <Button onClick={() => handleDeleteConfirmation(item.id)}>
                            {" "}
                            <Icon name="trash"></Icon>
                            <span>Delete </span>
                          </Button>
                        </DataTableRow>
                      </Check>
                    </DataTableItem>
                  );
                })
              ) : (
                <></>
              )}
            </DataTableBody>
          )}
          <div className="card-inner">
            {healthAndSafetyList && healthAndSafetyList.pagination && healthAndSafetyList.pagination.totalCount > 0 ? (
              <PaginationComponent
                itemPerPage={pagination.size}
                totalItems={healthAndSafetyList.pagination.totalCount}
                paginate={healthAndSafetyContext.updateShowListPage}
                paginatePage={healthAndSafetyContext.updateShowListSize}
                currentPage={healthAndSafetyList.pagination.currentPage}
              />
            ) : (
              !healthAndSafetyLoading && (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )
            )}
          </div>
        </DataTable>
        {healthAndSafetyLoading && <Skeleton count={10} className="w-100" />}
      </Block>
      <ModalViewer
        title="Medical Centre"
        size="md"
        isOpen={isOpen}
        toggleModal={toggleModal}
        component={getComponent()}
      />
      <ModalViewer
        title="Medical Centre - Select the Export Fields"
        isOpen={isExpotModalOpen}
        size="md"
        toggleModal={toggleExportModal}
        component={getExportComponent()}
      />
    </React.Fragment>
  );
};
export default HealthAndSafetyListCompact;
