export const STATUSES = [
    { label: "Open", value: "OPEN" },
    { label: "In Progress", value: "IN_PROGRESS" },
    { label: "Closed", value: "CLOSED" },
    { label: "Ready For Approval", value: "VALIDATE" }
]
export const SORT_OPTION = [
    // { label: "Plant Name(A-Z)", value: "plant" },
    // { label: "Plant Name(Z-A)", value: "-plant" },
    // { label: "Last Updated at(Latest)", value: "-updatedAt" },
    // { label: "Last Updated at(Oldest)", value: "updatedAt" }
    { label: "Identifier", value: "identifier" },
    { label: "Plant", value: "plant" },
    { label: 'Added At', value: 'addedAt' },
    { label: 'Investigated At', value: 'investigatedAt' },
    { label: 'Repaired At', value: 'repairedAt' },
    { label: 'Status', value: 'status' },
    { label: 'Failure Reason', value: 'failureReason' },
    { label: 'Monitoring Comments', value: 'monitoringComments' },
    { label: 'Works Comments', value: 'workComments' },
    { label: 'No.of Strings fixed per duct', value: 'noOfStringsPerDuct' },
]

export const ORDER_OPTION = [
    { label: 'Ascending', value: 'ASC' },
    { label: 'Descending', value: 'DESC' }

]
export const STRING_FILTER = "@/bge/string_filters"