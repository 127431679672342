/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Button } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  PaginationComponent,
  Sidebar,
  TooltipComponent,
} from "../../../components/Component";
import FilteredByList from "../../../components/FilteredBy";
import Check from "../../../components/check_roles/check";
import { confirmationSweetAlert } from "../../../components/sweetAlert/SweetAlert";
import { errorToast, successToast } from "../../../components/toastify/Toastify";
import { EQUIPMENT_SPARE_FILTERS } from "../../../constants/PreferenceKeys";
import { EquipmentAndSpareContext } from "../../equipment_spare/EquipmentAndSpareProvider";
import { deleteEquipmentAPI } from "../../equipment_spare/EquipmentAndSpareRepository";
import EquipmentAndSpareFilter from "../../equipment_spare/components/EquipmentAndSpareFilter";
import ExportComponent from "../../equipment_spare/components/ExportComponent";
import TransactionForm from "../../equipment_spare/components/Transaction";
import { STATUSES } from "../../equipment_spare/constants";
import EquipmentAndSpareForm from "../../equipment_spare/containers/EquipmentAndSpareForm";
import EquipmentAndSpareViewContainer from "../../equipment_spare/containers/EquipmentAndSpareViewContainer";
import ModalViewer from "../../maintenance/ModalViewer";
import { PvPlantManagementContext } from "../PvPlantManagementProvider";
import useBoolean from "../../../hooks/useBoolean";
import { createColumnHelper } from "@tanstack/react-table";
import SortableColumns from "../../../components/tableV1/SortableColumns";
import TanstackTable from "../../../components/tableV1/Table";
import { updateViewSettings } from "../../../repositories/ViewRepository";
import { TASK_FIELDS } from "../../../constants/constants";
import ToolTip from "../../../components/ToolTip";

const EquipmentAndSpareList = (props) => {
  const { plantId } = props;

  const {
    equipmentList,
    isExportLoading,
    handleApplyFilter,
    filterParams,
    filterOptions,
    isLoading,
    pagination,
    updateShowListPage,
    deleteEquipment,
    isFilterApplied,
    sfState,
    loadEquipmentList,
    equipmentListLoading,
    showFilter,
    deleteData,
    handleDeleteData,
    updateShowSizePage,
    equipmentColumnSettings,
    setEquipmentColumnSettings,
    setCurrentEquipmentList,
    getTableSettings
  } = useContext(EquipmentAndSpareContext);
  const pvPlantManagementContext = useContext(PvPlantManagementContext);
  const { currentViewPvPlant } = pvPlantManagementContext;
  const { id } = currentViewPvPlant;
  const focusSearchRef = React.useRef();

  const [sm, updateSm] = useState(false);
  const [view, setView] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [onSearch, setOnSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [equipmentId, setEquipmentId] = useState("");
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [isColumnArrangeModalOpen, icamState] = useBoolean(false);

  const supplier = {};
  const category = {};
  const plant = {};
  const VIEW = {
    VIEW: "VIEW",
    CREATE: "CREATE",
    EDIT: "EDIT",
    MOVE: "MOVE",
  };
  const KEY_COMPONENT_OPTION = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];
  const OWNED_BY_OPTIONS = [
    { label: "BGE Owned", value: "BGE" },
    { label: "Plant Owned", value: "PLANT" },
  ];

  const columnHelper = createColumnHelper();

  const fetchEquipmentsData = async () => {
    const settings = await getTableSettings({
      entityType: "EQUIPMENT_PLANT",
    });
    if (settings && id) {
      await loadEquipmentList(settings, id);
    }
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };
  const renderTableValue = (tableValue) => {
    if (!tableValue) {
      return <div>-</div>;
    }
    return <div data-toggle="tooltip" title={tableValue}>{tableValue} </div>;
  };

  //  column
  function tableColumn(columnSetting) {
    switch (columnSetting.field_key) {
      case "assetId":
        return columnHelper.accessor(columnSetting?.field_key, {
          id: columnSetting?.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => {
            return <p style={{ cursor: "pointer" }} onClick={() => {
              viewInventory(info.row.original?.id);
              setCurrentEquipmentList(info.row.original);
            }}>
              {info.row.original?.assets?.model}
            </p>
          }
        });
      case "plantId":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => renderTableValue(info.row.original?.plants?.name),
        });
      case "supplier":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          enableResizing: true,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => {
            return renderTableValue(info.row.original?.assets?.manufacturers?.name)
          },
        });
      case "category":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          enableResizing: true,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => {
            return renderTableValue(info.row.original?.assets?.assetCategories?.name)
          },
        });
      case "quantity":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => renderTableValue(info.getValue()),
        });
      case "contractualQuantity":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => renderTableValue(info.getValue()),

        });
      case "isKeyComponent":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => {
            return info.getValue() ? "True" : "False"
          }
        });
      case "ownership":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => renderTableValue(info.getValue()),
        });
      case "installedQuantity":
        return columnHelper.accessor(columnSetting.field_key, {
          enableSorting: false,
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => renderTableValue(info.getValue()),
        });
      case "spareQuantity":
        return columnHelper.accessor(columnSetting.field_key, {
          enableSorting: false,
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => renderTableValue(info.row.original?.spareQuantity)
        });
      case "externalWarehouseQuantity":
        return columnHelper.accessor(columnSetting.field_key, {
          enableSorting: false,
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => renderTableValue(info.getValue()),
        });
      case "bgeWarehouseQuantity":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          enableSorting: false,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => renderTableValue(info.getValue()),
        });
      case "supplierPremisesQuantity":
        return columnHelper.accessor(columnSetting.field_key, {
          enableSorting: false,
          id: columnSetting.field_key,
          header: () => columnSetting.field_key ?? "",
          cell: (info) => renderTableValue(info.getValue()),
        });
      case "description":
        return columnHelper.accessor(columnSetting.field_key, {
          enableSorting: false,
          id: columnSetting.field_key,
          header: () => columnSetting.field_key ?? "",
          cell: (info) => <ToolTip id={`id-${info.row.original?.id}-description`} value={info.getValue()}/>
        });
      case "partNumber":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => renderTableValue(info.getValue()),
        });
      default:
        return columnHelper.accessor(columnSetting.field_key, {
          id: "id",
          enableSorting: false,
          header: () => columnSetting.field_key ?? "",
          cell: (info) => {
            return <p>unknown column</p>;
          },
        });
    }
  }

  useEffect(() => {
    fetchEquipmentsData()
  }, [isOpen, deleteData]);

  for (let i = 0; i < filterOptions.assetCategory?.length; i++) {
    category[filterOptions.assetCategory[i].value] = filterOptions.assetCategory[i].label;
  }
  for (let i = 0; i < filterOptions.manufacturer?.length; i++) {
    supplier[filterOptions.manufacturer[i].value] = filterOptions.manufacturer[i].label;
  }
  for (let i = 0; i < filterOptions.plant?.length; i++) {
    plant[filterOptions.plant[i].value] = filterOptions.plant[i].label;
  }
  const handleNameSort = (sortOrder, field, table) => {
    handleApplyFilter({ ...filterParams, sortingOrder: sortOrder, sortingField: field, table: table });
    const updatedSettings = {
      ...equipmentColumnSettings,
      filters: { ...equipmentColumnSettings.filters, sortingField: field, sortingOrder: sortOrder },
    };
    handleUpdateViewSettings(updatedSettings);
    loadEquipmentList(updatedSettings);
    setEquipmentColumnSettings(updatedSettings);
  };


  const columns = React.useMemo(() => {
    if (equipmentColumnSettings?.fields?.length) {
      return equipmentColumnSettings.fields
        .filter((cs) => cs.isChecked)
        .map((columnSetting) => {
          return tableColumn(columnSetting);
        });
    }
    return [];
  }, [equipmentList?.items]);
  function renderAction() {
    return [
      columnHelper.accessor("id", {
        header: "",
        size: 80,
        enableSorting: false,
        enableResizing: false,
        cell: (info) => {
          const item = info?.row.original;
          return (
            <Check permission={["manageInventory"]}>
              <ul className="nk-tb-actions gx-1">
                <li className="nk-tb-action-hidden" onClick={() => editInventory(item.id)}>
                  <TooltipComponent
                    tag="a"
                    containerClassName="btn btn-trigger btn-icon"
                    id={"edit" + item.id}
                    icon="edit-alt-fill"
                    direction="top"
                    text="Edit"
                  />
                </li>
                {item.status !== "SUSPENDED" && (
                  <li className="nk-tb-action-hidden" onClick={() => handleDeleteConfirmation(item.id)}>
                    <TooltipComponent
                      tag="a"
                      containerClassName="btn btn-trigger btn-icon"
                      id={"delete-" + item.id}
                      icon="na"
                      direction="top"
                      text="Delete"
                    />
                  </li>
                )}
              </ul>
            </Check>
          );
        },
      }),
    ];
  }
  const renderTable = React.useMemo(() => {
    const columnMinWidth = [{ field: "assetId", width: 400 }]
    return (
      <TanstackTable
        dataSource={equipmentList?.items ?? []}
        _column={[...columns, ...renderAction()]}
        columnSettings={equipmentColumnSettings}
        columnMinWidth={columnMinWidth}
        onColumWidthChanged={(resizedColumn) => {
          const _equipmentColumnSettings = [...equipmentColumnSettings.fields].map((ps) => {
            if (ps.field_key === resizedColumn.field_key) {
              return {
                ...ps,
                width: resizedColumn.width,
              };
            } else {
              return ps;
            }
          });
          handleUpdateViewSettings({
            // ...equipmentColumnSettings,
            entityType: "EQUIPMENT_PLANT",
            fields: _equipmentColumnSettings,
          });
          setEquipmentColumnSettings((prev) => ({ ...prev, fields: _equipmentColumnSettings }));
        }}
        handleSorting={(sortingParams) => {
          handleNameSort(sortingParams.sortingOrder, sortingParams.sortingField);
        }}
      />
    );
  }, [columns, equipmentList?.items, equipmentColumnSettings]);
  async function handleUpdateViewSettings(payload) {
    return await updateViewSettings(payload);
  }


  const createInventory = () => {
    setView(VIEW.CREATE);
    setIsOpen(!isOpen);
    return <>Edit</>;
  };
  const viewInventory = (taskId) => {
    setEquipmentId(taskId);
    setView(VIEW.VIEW);
    toggleModal();
  };

  const editInventory = (taskId) => {
    setEquipmentId(taskId);
    setView(VIEW.EDIT);
    setIsOpen(true);
  };

  const toggle = () => setOnSearch(!onSearch);
  useEffect(() => {
    const filters = localStorage.getItem(EQUIPMENT_SPARE_FILTERS);
    if (filters) handleApplyFilter(JSON.parse(filters));
  }, []);

  const onSearchAction = () => {
    handleApplyFilter({ ...filterParams, searchText: onSearchText });
    loadEquipmentList({
      ...equipmentColumnSettings,
      filters: { ...equipmentColumnSettings.filters, searchText: onSearchText,  plant: [plantId] },
    });
  };

  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleDeleteInventory = async (inventoryId) => {
    await deleteEquipmentAPI(inventoryId)
      .then((res) => {
        successToast({ description: "Equipment is deleted successfully" });
        handleDeleteData(!deleteData);
      })
      .catch((e) => {
        errorToast({ description: "Error happened while deleting equipment" });
      });
  };

  const handleDeleteConfirmation = async (inventoryId) => {
    confirmationSweetAlert({
      id: inventoryId,
      handleConfirmation: handleDeleteInventory,
    });
  };

  const getComponent = () => {
    if (view === VIEW.VIEW) {
      return (
        <EquipmentAndSpareViewContainer
          mode={view}
          setMode={setView}
          onSuccessfulModal={toggleModal}
          equipmentId={equipmentId}
        />
      );
    } else if (view === VIEW.CREATE) {
      return (
        <EquipmentAndSpareForm module={"plantView"} mode={view} setMode={setView} onSuccessfulModal={toggleModal} />
      );
    } else if (view === VIEW.EDIT) {
      return (
        <EquipmentAndSpareForm
          module={"plantView"}
          mode={view}
          setMode={setView}
          onSuccessfulModal={toggleModal}
          equipmentId={equipmentId}
        />
      );
    } else if (view === VIEW.MOVE) {
      return <TransactionForm onSuccessfulModal={toggleModal} />;
    }
  };

  const getExportComponent = () => {
    return <ExportComponent plantId={[plantId]} setIsExportModalOpen={setIsExportModalOpen} />;
  };
  const manufacturerSelectedFilter = filterParams.supplier.map((item, key) => {
    return filterOptions.manufacturer?.[filterOptions.manufacturer?.findIndex((data) => data.value === item)];
  });

  const assetCategoryFilter = filterParams.category.map((item, key) => {
    return filterOptions.assetCategory?.[filterOptions.assetCategory?.findIndex((data) => data.value === item)];
  });
  const plantFilter = filterParams.plant.map((item, key) => {
    return filterOptions.plant?.[filterOptions.plant?.findIndex((data) => data.value === item)];
  });
  const selectedStatusFilter = STATUSES.find((data) => data.value === filterParams?.status);
  const keyoptionFilter = KEY_COMPONENT_OPTION.find((data) => data.value === filterParams?.isKeyComponent);

  const selectedOwnerShipFilter = OWNED_BY_OPTIONS.find((data) => data.value === filterParams?.ownership);


  return (
    <React.Fragment>
      <BlockHead size="sm">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h3" page>
              Equipment And Spares
            </BlockTitle>
            <BlockDes className="text-soft">
              <p>You have a total of {equipmentList?.pagination?.totalCount} Equipment and Spares.</p>
            </BlockDes>
            <div className="d-flex flex-wrap">
              {filterParams.status ? (
                <FilteredByList data={selectedStatusFilter.label} title={"Status"} isNotMap={true} isStr={true} />
              ) : null}
              {filterParams.isKeyComponent ? (
                <FilteredByList data={keyoptionFilter.label} title={"Key Component"} isNotMap={true} isStr={true} />
              ) : null}
              {filterParams.ownership ? (
                <FilteredByList data={selectedOwnerShipFilter.label} title={"Owned By"} isNotMap={true} isStr={true} />
              ) : null}
              <FilteredByList data={manufacturerSelectedFilter} title={"Supplier"} isMap={true} isStr={false} />
              <FilteredByList data={assetCategoryFilter} title={"Category"} isMap={true} isStr={false} />
              <FilteredByList data={filterParams.searchText} title="Searched by" isNotMap={true} isStr={true} />
            </div>
          </BlockHeadContent>
          <BlockHeadContent>
            <Sidebar toggleState={showFilter}>
              <div>
                <EquipmentAndSpareFilter plantId={currentViewPvPlant?.id} />
              </div>
            </Sidebar>
            <div className="toggle-wrap nk-block-tools-toggle">
              <Button
                className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                onClick={() => updateSm(!sm)}
              >
                <Icon name="menu-alt-r"></Icon>
              </Button>
              <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                <ul className="nk-block-tools g-3">
                  <Check permission={["getInventory", "manageInventory"]}>
                    <li>
                      <a
                        className={`btn btn-white btn-outline-light ${equipmentList?.pagination?.totalCount === 0 ? "disabled" : ""
                          }`}
                        type="export"
                        isLoading={isExportLoading}
                        onClick={() => {
                          setIsExportModalOpen(true);
                        }}
                      >
                        <Icon name="download-cloud"> </Icon>
                        <span>Export</span>
                      </a>
                    </li>
                  </Check>
                  <Check permission={["manageInventory"]}>
                    {currentViewPvPlant?.status === "ACTIVE" && currentViewPvPlant?.isArchived === false && (
                      <li className="nk-block-tools-opt">
                        <Button color="primary" className="btn-icon" onClick={createInventory}>
                          <Icon name="plus"></Icon>
                        </Button>
                      </li>
                    )}
                  </Check>
                </ul>
              </div>
            </div>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
      <Block>
        <DataTable className="card-stretch">
          <div className="card-inner position-relative card-tools-toggle">
            <div className="card-title-group" style={{ justifyContent: "end" }}>
              <div className="card-tools mr-n1">
                <ul className="btn-toolbar gx-1">
                  <li>
                    <a
                      href="#search"
                      onClick={(ev) => {
                        ev.preventDefault();
                        toggle();
                      }}
                      className="btn btn-icon search-toggle toggle-search"
                    >
                      <Icon name="search"></Icon>
                    </a>
                  </li>
                  <li className="btn-toolbar-sep"></li>
                  <li>
                    <div id="filter-plantstring" tag="a" className="btn btn-icon " onClick={() => sfState.toggle()}>
                      {isFilterApplied ? <div className="dot dot-primary"></div> : null}
                      <Icon name="filter-alt"></Icon>
                    </div>
                    {(equipmentList?.pagination.totalCount > 0) &&
                      <div id="equipment-filter" tag="a" className="btn btn-icon " onClick={() => icamState.on()}>
                        <Icon name="view-list-fill"></Icon>
                      </div>
                    }
                  </li>
                </ul>
              </div>
            </div>
            <ModalViewer
              title="Equipment and Spares Columns"
              isOpen={isColumnArrangeModalOpen}
              size="md"
              toggleModal={icamState.toggle}
              component={
                <SortableColumns
                  entityType={"EQUIPMENT_PLANT"}
                  fields={TASK_FIELDS}
                  updateViewSettings={async (modifiedSettings) => {
                    const updatedSettings = await handleUpdateViewSettings({
                      ...equipmentColumnSettings,
                      fields: modifiedSettings,
                    });
                    setEquipmentColumnSettings((prev) => ({ ...prev, fields: updatedSettings.fields }));
                    await loadEquipmentList(updatedSettings, plantId);
                  }}
                  closeModal={icamState.off}
                  visibleColumns={equipmentColumnSettings.fields}
                />
              }
            />
            <div className={`card-search search-wrap ${!onSearch && "active"}`}>
              <div className="card-body">
                <div className="search-content">
                  <Button
                    className="search-back btn-icon toggle-search active"
                    onClick={() => {
                      setSearchText("");
                      handleApplyFilter({ ...filterParams, searchText: "" });
                      toggle();
                      loadEquipmentList({
                        ...equipmentColumnSettings,
                        filters: { ...equipmentColumnSettings.filters, searchText: "", plant: [plantId]},
                      });
                    }}
                  >
                    <Icon name="arrow-left"></Icon>
                  </Button>
                  <input
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        onSearchAction();
                      }
                      if (e.keyCode === 27) {
                        setSearchText("");
                        handleApplyFilter({ ...filterParams, searchText: "" });
                        toggle();
                      }
                    }}
                    type="text"
                    className="border-transparent form-focus-none form-control"
                    placeholder="Search by Equipment name, Asset Category and Plant name"
                    ref={(el) => {
                      focusSearchRef.current = !onSearch ? el : null;
                    }}
                    value={onSearchText}
                    onChange={(e) => onFilterChange(e)}
                  />
                  <Button className="search-submit btn-icon">
                    <Icon name="search" onClick={onSearchAction}></Icon>
                  </Button>
                </div>
              </div>
            </div>
          </div>


          {isLoading ? <Skeleton count={10} className="w-100" /> :
            (equipmentList?.pagination?.totalCount > 0) && renderTable}

          <div className="card-inner">
            {equipmentList?.pagination && equipmentList?.pagination.totalCount > 0 ? (
              <PaginationComponent
                itemPerPage={pagination?.size}
                totalItems={equipmentList?.pagination?.totalCount}
                paginate={(page) => {
                  loadEquipmentList({
                    ...equipmentColumnSettings,
                    filters: { ...equipmentColumnSettings.filters, page },
                  }, plantId);
                  updateShowListPage(page);
                }}
                currentPage={equipmentList?.pagination?.currentPage}
                paginatePage={(size) => {
                  updateShowListPage(size);
                  const updatedSetting = {
                    ...equipmentColumnSettings,
                    filters: { ...equipmentColumnSettings.filters, size }
                  };
                  handleUpdateViewSettings(updatedSetting);
                  setEquipmentColumnSettings(updatedSetting);
                  loadEquipmentList(updatedSetting, plantId);
                }} />
            ) : (
              !isLoading && (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )
            )}
          </div>
        </DataTable>
      </Block>
      <ModalViewer
        size={"xl"}
        title="Equipment And Spares"
        isOpen={isOpen}
        toggleModal={toggleModal}
        component={getComponent()}
      />
      <ModalViewer
        title="Equipment And Spares - Select the Export Fields"
        isOpen={isExportModalOpen}
        size="md"
        toggleModal={() => {
          setIsExportModalOpen(!isExportModalOpen);
        }}
        component={getExportComponent()}
      />
    </React.Fragment>
  );
};
export default EquipmentAndSpareList;
