import classnames from "classnames";
import { format } from "date-fns";
import React, { useContext } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  FormGroup,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import { Block, BlockHead, BlockHeadContent, BlockTitle, Button, Icon } from "../../../components/Component";
import ActivityLog from "../../../components/activity_log/components/ActivityLog";
import Attachments from "../../../components/attachments/Attachment";
import Check from "../../../components/check_roles/check";
import { confirmationSweetAlert } from "../../../components/sweetAlert/SweetAlert";
import { errorToast, successToast } from "../../../components/toastify/Toastify";
import { PLANT } from "../../../constants/PreferenceKeys";
import { PVPLANT_MANAGEMENT_EDIT_PAGE, PVPLANT_MANAGEMENT_PAGE } from "../../../constants/routes";
import Head from "../../../layout/head/Head";
import StringList from "../../maintenance/string/components/StringList";
import PreventativeScheduleListContainer from "../../preventative_schedule/containers/PreventativeScheduleListContainer";
import { PvPlantManagementContext } from "../PvPlantManagementProvider";
import EquipmentAndSpareListContainer from "../containers/PvPlantEquipmentContainer";
import PlantCorrectiveList from "./PvPlantCorrectiveMaintenanceList";
import PlantPreventiveList from "./PvPlantPreventiveMaintananceList";
import CctvSystem from "./cctvSystem";
import PvPlantRemedialMaintenanceContainer from "../containers/PvPlantRemedialContainer";
import PvPlantPreventativeContainer from "../containers/PvPlantPreventativeContainer";
import PvPlantCorrectiveContainer from "../containers/PvPlantCorrectiveContainer";
import { MAINTENANCE_CATEGORY } from "../../../constants/constants";

const ViewPvPlant = ({ sm, updateSm }) => {
  //to get current location
  const location = useLocation().search;
  const tab = new URLSearchParams(location).get("tab");

  const history = useHistory();
  const params = useParams();
  const CheckTab = () => {
    switch (tab) {
      case "basic":
        return "1";
      case "corrective":
        return "2";
      case "preventative":
        return "3";
      case "remedial":
        return "4";
      case "string":
        return "5";
      case "equipmentAndSpares":
        return "6";
      case "master":
        return "7";
      case "cctv":
        return "8";
      default:
        return "1";
    }
  };
  const isTabCheck = CheckTab();

  const tabStatus = () => {
    switch (tab) {
      case "corrective":
        return;
      case "preventative":
        return "3";
      case "remedial":
        return "4";
      case "string":
        return "5";
      case "master":
        return "6";
      case "cctv":
        return "7";
      default:
        return "1";
    }
  };
  React.useEffect(() => {
    if (tab == "basic") {
      loadPvPlantTask(plantId);
    }
  }, [isTabCheck]);

  const { plantId } = params;

  const pvPlantManagementContext = useContext(PvPlantManagementContext);
  const { currentViewPvPlant, userList, deletePvPlant, plantTasks, loadPvPlantTask } = pvPlantManagementContext;

  if (!currentViewPvPlant) return <Spinner />;

  let fieldEngineers = {};

  for (let i = 0; i < userList?.length; i++) {
    fieldEngineers[userList[i].value] = userList[i].label;
  }

  const handleDeletePvPlant = async (plantId) => {
    deletePvPlant(plantId)
      .then((res) => {
        successToast({ description: "PV-Plant is deleted successfully" });
        history.replace(PVPLANT_MANAGEMENT_PAGE);
      })
      .catch((e) => {
        errorToast({ description: "Error happened while deleting PV-Plant" });
      });
  };
  const handleDeleteConfirmationPlant = async (plantId) => {
    confirmationSweetAlert({
      id: plantId,
      handleConfirmation: handleDeletePvPlant,
    });
  };

  const header = (props) => {
    switch (props) {
      case "correctiveTaskTotal":
        return "Corrective";
      case "preventativeTaskTotal":
        return "Preventative (Current month)";
      case "remedialTaskTotal":
        return "Remedial";
      case "stringTotal":
        return "String";

      default:
        return "n/a";
    }
  };
  const switchRoutes = (props) => {
    switch (props) {
      case "correctiveTaskTotal":
        return "corrective";
      case "preventativeTaskTotal":
        return "preventative";
      case "remedialTaskTotal":
        return "remedial";
      case "stringTotal":
        return "string";
      default:
        return "n/a";
    }
  };

  const titleData = (props) => {
    switch (props) {
      case "OPEN":
        return <div style={{ color: "red" }}>Open</div>;
      case "IN_PROGRESS":
        return <div style={{ color: "green" }}>In Progress</div>;
      case "READY_FOR_APPROVAL":
        return <div style={{ color: "deeppink", display: "flex", textAlign: "center" }}>Ready For Approval</div>;
      case "COMPLETED":
        return <div style={{ color: "blue" }}>Closed</div>;
      case "VALIDATE":
        return <div style={{ color: "deeppink", display: "flex", textAlign: "center" }}>Ready For Approval</div>;
      case "CLOSED":
        return <div style={{ color: "blue" }}>Closed</div>;
      default:
        return "n/a";
    }
  };
  const plantTaskDataKeys = Object.entries(plantTasks);

  return (
    <>
      <BlockHeadContent className="mb-1">
        <Head title="Brighter App | Pv-Plant | View" />
        <div style={{ display: "flex", alignItems: "center" }}>
          <Link to={PVPLANT_MANAGEMENT_PAGE}>
            <Button color="light" outline className="bg-white d-none d-sm-inline-flex">
              <Icon name="arrow-left"></Icon>
              <span>Back</span>
            </Button>
          </Link>
          <div style={{ fontWeight: "bold", fontSize: 20, marginLeft: 5 }}>{currentViewPvPlant.name} PV Plant</div>
        </div>
      </BlockHeadContent>
      <Block className={"bg-white mt-3"}>
        <Nav tabs className="ml-4" style={{ display: "flex", justifyContent: "space-around" }}>
          <NavItem>
            <NavLink
              id="info-Plants-btn"
              href="#tab"
              className={classnames({ active: isTabCheck === "1" })}
              onClick={(ev) => {
                ev.preventDefault();
                history.push(`${window.location.pathname}?tab=basic`);
              }}
            >
              Plant Info
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              id="corrective-Plants-btn"
              href="#tab"
              className={classnames({ active: isTabCheck === "2" })}
              onClick={(ev) => {
                ev.preventDefault();
                history.push(`${window.location.pathname}?tab=corrective`);
              }}
            >
              Corrective
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              id="prevevtative-Plants-btn"
              tag="a"
              href="#tab"
              className={classnames({ active: isTabCheck === "3" })}
              onClick={(ev) => {
                ev.preventDefault();
                history.push(`${window.location.pathname}?tab=preventative`);
              }}
            >
              Preventative
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              id="remedial-Plants-btn"
              tag="a"
              href="#tab"
              className={classnames({ active: isTabCheck === "4" })}
              onClick={(ev) => {
                ev.preventDefault();
                history.push(`${window.location.pathname}?tab=remedial`);
              }}
            >
              Remedial
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              id="string-Plants-btn"
              tag="a"
              href="#tab"
              className={classnames({ active: isTabCheck === "5" })}
              onClick={(ev) => {
                ev.preventDefault();
                history.push(`${window.location.pathname}?tab=string`);
              }}
            >
              String
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              id="equipment-Plants-btn"
              href="#tab"
              className={classnames({ active: isTabCheck === "6" })}
              onClick={(ev) => {
                ev.preventDefault();
                history.push(`${window.location.pathname}?tab=equipmentAndSpares`);
              }}
            >
              Equipment
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              id="schedule-Plants-btn"
              tag="a"
              href="#tab"
              className={classnames({ active: isTabCheck === "7" })}
              onClick={(ev) => {
                ev.preventDefault();
                history.push(`${window.location.pathname}?tab=master`);
              }}
            >
              Preventative Schedule Master
            </NavLink>
          </NavItem>
          <hr />
        </Nav>
        <TabContent className="mt-2" activeTab={isTabCheck}>
          <TabPane tabId="1">
            <Block className={"bg-white p-4"}>
              <BlockHead size="lg">
                <div className="justify-content-mb-between d-md-flex">
                  <BlockHeadContent>
                    <BlockTitle tag="h5">{currentViewPvPlant.name}'s Information</BlockTitle>
                  </BlockHeadContent>
                  <BlockHeadContent className="align-self-end mt-3 mt-md-0">
                    <Check permission={["deletePlant"]}>
                      <Button
                        id="delete-Plants-btn"
                        color="danger"
                        className={"mr-2"}
                        onClick={() => {
                          handleDeleteConfirmationPlant(currentViewPvPlant.id);
                        }}
                      >
                        <Icon name="na" />
                        <span>Delete</span>
                      </Button>
                    </Check>
                    {currentViewPvPlant.isArchived === false && (
                      <Check permission={["updatePlant"]}>
                        <Link
                          id="edit-Plants-btn"
                          to={{
                            pathname: PVPLANT_MANAGEMENT_EDIT_PAGE.replace(":plantId", currentViewPvPlant.id),
                            state: { viewState: true },
                          }}
                        >
                          <Button color="primary">
                            <Icon name="edit" />
                            <span>Edit</span>
                          </Button>
                        </Link>
                      </Check>
                    )}
                  </BlockHeadContent>
                </div>
              </BlockHead>
              <Row>
                {plantTaskDataKeys &&
                  plantTaskDataKeys.map((taskData, index) => {
                    return (
                      <Col md="6" lg="6" sm="12" className="p-2" m="2" key={`dashboard-${index}`}>
                        <Card className="card-bordered">
                          <CardTitle className="m-3" style={{ fontWeight: "bolder", fontSize: "20px" }}>
                            {header(taskData[0])}
                          </CardTitle>
                          <CardBody>
                            <Row className="match-height form-row">
                              {taskData[1].length &&
                                taskData[1].map((data, index) => {
                                  return (
                                    <Col md="3" lg="3" key={`data-${index}`}>
                                      <Card
                                        id="count-1"
                                        style={{ cursor: "pointer" }}
                                        onClick={(ev) => {
                                          ev.preventDefault();

                                          history.push(
                                            `${window.location.pathname}?tab=${switchRoutes(taskData[0])}&status=${data.status
                                            }&dashboard=yes`
                                          );
                                        }}
                                      >
                                        {data.status === "OPEN" && (
                                          <CardTitle
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              fontSize: "40px",
                                              color: "red",
                                            }}
                                          >
                                            {data.count}
                                          </CardTitle>
                                        )}
                                        {(data.status === "COMPLETED" || data.status === "CLOSED") && (
                                          <CardTitle
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              fontSize: "40px",
                                              color: "blue",
                                            }}
                                          >
                                            {data.count}
                                          </CardTitle>
                                        )}
                                        {(data.status === "READY_FOR_APPROVAL" || data.status === "VALIDATE") && (
                                          <CardTitle
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              fontSize: "40px",
                                              color: "deeppink",
                                              cursor: "pointer",
                                            }}
                                          >
                                            {data.count}
                                          </CardTitle>
                                        )}
                                        {data.status === "IN_PROGRESS" && (
                                          <CardTitle
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              fontSize: "40px",
                                              color: "green",
                                            }}
                                          >
                                            {data.count}
                                          </CardTitle>
                                        )}
                                        <CardBody
                                          style={{ display: "flex", justifyContent: "center", fontWeight: "bolder" }}
                                          className="p-0"
                                        >
                                          {titleData(data.status)}
                                        </CardBody>
                                      </Card>
                                    </Col>
                                  );
                                })}
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    );
                  })}
              </Row>

              <Block size="lg" className="mt-2">
                <Card className="card-bordered p-2">
                  <div className="profile-ud-list" style={{ maxWidth: "100%" }}>
                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label">Plant Name</span>
                        <span className="profile-ud-value">{currentViewPvPlant.name || "-"}</span>
                      </div>
                    </div>
                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label">Size</span>
                        <span className="profile-ud-value">{currentViewPvPlant?.size || "-"}</span>
                      </div>
                    </div>
                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label">Acronym</span>
                        <span className="profile-ud-value">{currentViewPvPlant?.identifier || "-"}</span>
                      </div>
                    </div>
                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label">Status</span>
                        <span className="profile-ud-value">{currentViewPvPlant?.status || "-"}</span>
                      </div>
                    </div>
                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label"> Client Name</span>
                        <span className="profile-ud-value">
                          {currentViewPvPlant.clients ? currentViewPvPlant.clients.name : "-"}
                        </span>
                      </div>
                    </div>
                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label"> Plant Manager</span>
                        <span className="profile-ud-value">
                          {currentViewPvPlant.plantManager
                            ? `${currentViewPvPlant.plantManager.firstName} ${currentViewPvPlant.plantManager.lastName}`
                            : "-"}
                        </span>
                      </div>
                    </div>
                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label"> Team Leader</span>
                        <span className="profile-ud-value">
                          {currentViewPvPlant.users
                            ? `${currentViewPvPlant.users.firstName} ${currentViewPvPlant.users.lastName}`
                            : "-"}
                        </span>
                      </div>
                    </div>
                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label"> Field Engineer</span>
                        <span className="profile-ud-value">
                          {currentViewPvPlant?.fieldEngineerIds
                            ?.map((id, key) => {
                              return fieldEngineers[id];
                            })
                            .join(", ") || "-"}
                        </span>
                      </div>
                    </div>
                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label"> Address</span>
                        <span className="profile-ud-value">{currentViewPvPlant?.address || "-"}</span>
                      </div>
                    </div>
                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label"> OnBoarded At</span>
                        <span className="profile-ud-value">
                          {currentViewPvPlant.onboardedAt
                            ? format(new Date(currentViewPvPlant.onboardedAt || null), "dd/MM/yyyy")
                            : "-"}
                        </span>
                      </div>
                    </div>
                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label"> DNO</span>
                        <span className="profile-ud-value">{currentViewPvPlant.dnos?.name || "-"}</span>
                      </div>
                    </div>
                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label"> Hospital</span>
                        <span className="profile-ud-value">{currentViewPvPlant.medicalCenters?.name || "-"}</span>
                      </div>
                    </div>
                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label"> What3Word Link</span>
                        <span className="profile-ud-value ">
                          {currentViewPvPlant.what3WordLink ? (
                            <NavLink
                              id="web-pvplant"
                              className="p-0"
                              href={currentViewPvPlant.what3WordLink}
                              target={"_blank"}
                            >
                              {currentViewPvPlant?.what3WordLink}
                            </NavLink>
                          ) : (
                            "-"
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label">GoogleMap Link</span>
                        <span className="profile-ud-value pt-0 text-break">
                          {currentViewPvPlant.googleMapLink ? (
                            <NavLink
                              id="map-Plants"
                              className="p-0"
                              href={currentViewPvPlant.googleMapLink}
                              target={"_blank"}
                            >
                              {currentViewPvPlant?.googleMapLink}
                            </NavLink>
                          ) : (
                            "-"
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label"> CreatedAt</span>
                        <span className="profile-ud-value">
                          {format(new Date(currentViewPvPlant?.created_at || null), "dd/MM/yyyy HH:mm ")}
                        </span>
                      </div>
                    </div>
                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label"> UpdatedAt</span>
                        <span className="profile-ud-value">
                          {format(new Date(currentViewPvPlant?.updated_at || null), "dd/MM/yyyy HH:mm")}
                        </span>
                      </div>
                    </div>
                  </div>
                </Card>
              </Block>
              <Block size="lg" className="mt-2">
                <CctvSystem />
              </Block>
              <Block className={"bg-white mt-3"}>
                <Card className="card-bordered p-2">
                  {
                    <Col lg="12" className=" ">
                      <FormGroup className="form-group">
                        <div className="form-control-wrap">
                          <Attachments
                            module={PLANT}
                            id={currentViewPvPlant.id}
                            attachments={currentViewPvPlant.attachment}
                            subModule={"view"}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                  }
                </Card>
              </Block>
              <Block>
                <Card className="card-bordered p-2">
                  <ActivityLog id={plantId} entityType="PLANT" />
                </Card>
              </Block>
            </Block>
          </TabPane>
          <TabPane tabId="2">
            <PvPlantCorrectiveContainer plantId={currentViewPvPlant.id} plantStatus={currentViewPvPlant.status} isArchived={currentViewPvPlant.isArchived} />
          </TabPane>
          <TabPane tabId="3">
            <PvPlantPreventativeContainer
              plantId={currentViewPvPlant.id}
              isArchived={currentViewPvPlant.isArchived}
              plantStatus={currentViewPvPlant.status}
            />
          </TabPane>
          <TabPane tabId="4">
            <PvPlantRemedialMaintenanceContainer plantStatus={currentViewPvPlant.status}
              isArchived={currentViewPvPlant.isArchived}
              plantId={plantId}
            />
          </TabPane>
          <TabPane tabId="5">
            {tab === MAINTENANCE_CATEGORY.STRING ? (
              <Block className={"bg-white mt-3 p-4"}>
                <StringList
                  currentViewPvPlantId={currentViewPvPlant.id}
                  plantStatus={currentViewPvPlant.status}
                  isArchived={currentViewPvPlant.isArchived}
                />
              </Block>
            ) : null}
          </TabPane>
          <TabPane tabId="6">
            <Block className={"bg-white mt-3 "}>
              <EquipmentAndSpareListContainer
                plantId={currentViewPvPlant.id}
                plantStatus={currentViewPvPlant.status}
                isArchived={currentViewPvPlant.isArchived}
              />
            </Block>
          </TabPane>
          <TabPane tabId="7">
            <Block className={"bg-white mt-3 "}>
              <PreventativeScheduleListContainer
                plantId={currentViewPvPlant.id}
                plantStatus={currentViewPvPlant.status}
                isArchived={currentViewPvPlant.isArchived}
              />
            </Block>
          </TabPane>
        </TabContent>
      </Block>
    </>
  );
};

export default ViewPvPlant;
