import React, { useContext, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Link, useHistory } from "react-router-dom";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  DataTable,
  Icon,
  PaginationComponent,
  Sidebar,
  UserAvatar,
} from "../../../components/Component";
import FilteredByList from "../../../components/FilteredBy";
import Check from "../../../components/check_roles/check";
import { confirmationSweetAlert } from "../../../components/sweetAlert/SweetAlert";
import { errorToast, successToast } from "../../../components/toastify/Toastify";
import {
  PVPLANT_MANAGEMENT_CREATE_PAGE,
  PVPLANT_MANAGEMENT_EDIT_PAGE,
  PVPLANT_MANAGEMENT_VIEW_PAGE,
} from "../../../constants/routes";
import { findUpper, openImageInNewTab } from "../../../utils/Utils";
import ModalViewer from "../../maintenance/ModalViewer";
import { PvPlantManagementContext } from "../PvPlantManagementProvider";
import ExportForm, { PLANT_FIELDS } from "./ExportForm";
import PvPlantListFilter from "./PvPlantListFilter";
import TanstackTable from "../../../components/tableV1/Table";
import { createColumnHelper } from "@tanstack/react-table";
import useBoolean from "../../../hooks/useBoolean";
import SortableColumns from "../../../components/tableV1/SortableColumns";
import { updateViewSettings } from "../../../repositories/ViewRepository";
import { format } from "date-fns";
import { createPortal } from "react-dom";
import AttachmentList from "../../../components/FileAttachment";

const columnHelper = createColumnHelper();

const PvPlantListCompact = () => {
  // REFS
  const focusSearchRef = React.useRef();

  const history = useHistory();

  const pvPlantManagementContext = useContext(PvPlantManagementContext);

  const { pvPlantsList, pagination, pvPlantColumnSettings, setPvPlantsColumnSettings } = pvPlantManagementContext;
  const { filterParams, handleApplyFilter, showFilter, isFilterApplied, sfState, clientList, userList } =
    pvPlantManagementContext;
  const { archivePvPlant, unarchivePvPlant, deletePvPlant, pvPlantLoading } = pvPlantManagementContext;
  const [sm, updateSm] = useState(false);
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [exportLoading, setExportLoading] = useState(false);
  const [isExpotModalOpen, setIsExportModalOpen] = useState(false);
  const [isColumnArrangeModalOpen, icamState] = useBoolean(false);
  const [selectedAttachment, setSelectedAttachment] = useState({});
  const [isAttachmentModalOpen, iamoState] = useBoolean(false);

  const renderTableValue = (tableValue) => {
    if (!tableValue) {
      return <div>-</div>;
    }
    return tableValue;
  };
  //  column
  function tableColumn(columnSetting) {
    switch (columnSetting.field_key) {
      case "name":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => "PV Plant",
          cell: (info) => {
            return (
              <Link id="pvplant-view" to={`${PVPLANT_MANAGEMENT_VIEW_PAGE+"?tab=basic"}`.replace(":plantId", info.row.original.id)}>
                <div style={{ display: "flex", gap: "10px" }}>
                  <UserAvatar theme={"primary"} className="xs" text={findUpper(info.getValue())} />
                  <p style={{ fontWeight: "bold", color: "#364a63" }}>{renderTableValue(info.getValue())}</p>
                </div>
              </Link>
            );
          },
        });
      case "status":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => <p>{info.getValue() ? info.getValue() : "-"}</p>,
        });
      case "identifier":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => <p>{info.getValue() ? info.getValue() : "-"}</p>,
        });
      case "postalCode":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => {
            if (info.row.original?.googleMapLink) {
              return <a target={info.row.original?.googleMapLink ? "_blank" : ""} href={info.row.original?.googleMapLink || ""}>{info.getValue() ? info.getValue() : "-"}</a>
            } else {
              return <p>{info.getValue() ? info.getValue() : "-"}</p>
            }
          }
        });
      case "size":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => <p>{info.getValue() ? info.getValue() : "-"}</p>,
        });
      case "clientId":
        return columnHelper.accessor("clients", {
          id: "clientId",
          enableResizing: true,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => {
            return <span className="wrapField">{info.row.original.clients?.name ? info.row.original.clients?.name : "-"}</span>;
          },
        });
      case "address":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => <p>{info.getValue() ? info.getValue() : "-"}</p>
        });
      case "plantManagerId":
        return columnHelper.accessor("plantManager", {
          id: "plantManagerId",
          enableResizing: true,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => {
            return (
              info.row.original.plantManager?.firstName ? <p className="wrapField">
                {info.row.original.plantManager?.firstName} {info.row.original.plantManager?.lastName}{" "}
              </p> : <p>-</p>
            );
          },
        });
      case "onboardedAt":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => {
            if (info.row.original?.onboardedAt) {
              const date = new Date(info.row.original?.onboardedAt);
              const formattedDate = date.toISOString().replace("T", " ").slice(0, 19)
              return <p> {format(new Date(formattedDate), "dd-MM-yyyy")}
              </p>;
            }

            return <p>-</p>;
          },
        });
      case "isArchived":
        return columnHelper.accessor(columnSetting.field_key, {
          enableSorting: false,
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => <p>{info.getValue() ? "Archived" : "UnArchived"}</p>,
        });
      case "googleMapLink":
        return columnHelper.accessor(columnSetting.field_key, {
          enableSorting: false,
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => renderTableValue(info.getValue()),
        });
      case "what3WordLink":
        return columnHelper.accessor(columnSetting.field_key, {
          enableSorting: false,
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => <p>{info.getValue() ? info.getValue() : "-"}</p>,
        });
      case "dnoId":
        return columnHelper.accessor("dnos", {
          id: "dnoId",
          enableSorting: true,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => {
            return <p>{info.row.original.dnos?.name ? info.row.original.dnos?.name : "-"}</p>;
          },
        });
      case "medicalCenterId":
        return columnHelper.accessor("medicalCenters", {
          enableSorting: true,
          id: "medicalCenterId",
          header: () => columnSetting.field_name ?? "",
          cell: (info) => <p>{info.row.original?.medicalCenters?.name ? info.row.original?.medicalCenters?.name : "-"}</p>,
        });
      case "teamLeaderId":
        return columnHelper.accessor("users", {
          id: "teamLeaderId",
          enableResizing: true,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => (
            info.row.original?.users?.firstName ? <p className="wrapField">
              {info.row.original?.users?.firstName}
              {info.row.original?.users?.lastName}
            </p> : <p>-</p>
          ),
        });
      case "createdById":
        return columnHelper.accessor("createdBy", {
          id: "createdById",
          header: () => columnSetting.field_name ?? "",
          cell: (info) => {
            if (info.row.original?.createdBy) {

              return (
                info.row.original?.createdBy.firstName ? <p>
                  {info.row.original?.createdBy.firstName}
                  {info.row.original?.createdBy.lastName}
                </p> : <p>-</p>
              );
            }
            return "-";
          },
        });
      case "fieldEngineerIds":
        return columnHelper.accessor("fieldEngineerIds", {
          id: columnSetting.field_key,
          enableSorting: true,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => {
            if (!info.row.original?.fieldEngineerIds?.length) {
              return <p>-</p>;
            }
            const user = userList.filter((item) => info.row.original?.fieldEngineerIds.includes(item.value));
            return (
              user
                ?.map((id, key) => {
                  return id?.label;
                })
                .join(", ")
            );
          },
        });
      case "attachment":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          enableSorting: false,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => <AttachmentList
            attachments={info.row.original?.attachment}
            taskName={info.row.original?.title}
            openImageInNewTab={openImageInNewTab}
            setSelectedAttachment={setSelectedAttachment}
            iamoState={iamoState}
          />
        });
      default:
        return columnHelper.accessor(columnSetting.field_key, {
          id: "id",
          enableSorting: false,
          header: () => columnSetting.field_key ?? "",
          cell: (info) => {
            return <p>unknown column</p>;
          },
        });
    }
  }

  useEffect(() => {
    if (focusSearchRef.current) focusSearchRef.current.focus();
  });

  // Changing state value when searching name
  useEffect(() => { }, [onSearchText]);

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  // function that loads the want to editted data
  const onEditClick = (id) => {
    history.push(PVPLANT_MANAGEMENT_EDIT_PAGE.replace(":plantId", id));
  };

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  const handleArchive = async (plantId) => {
    archivePvPlant(plantId)
      .then((res) => {
        successToast({ description: "PV-Plant Archived successfully" });
      })
      .catch((e) => {
        errorToast({ description: "Error happened while archive PV-Plant" });
      });
  };
  const handleUnArchive = async (plantId) => {
    unarchivePvPlant(plantId)
      .then((res) => {
        successToast({ description: "PV-Plant UnArchived successfully" });
      })
      .catch((e) => {
        errorToast({ description: "Error happened while unarchive PV-Plant" });
      });
  };

  const handleDeletePvPlant = async (plantId) => {
    deletePvPlant(plantId)
      .then((res) => {
        successToast({ description: "PV-Plant is deleted successfully" });
      })
      .catch((e) => {
        errorToast({ description: "Error happened while deleting PV-Plant" });
      });
  };
  const handleDeleteConfirmation = async (plantId) => {
    confirmationSweetAlert({
      id: plantId,
      handleConfirmation: handleDeletePvPlant,
    });
  };

  const onSearchAction = () => {
    handleApplyFilter({ ...filterParams, searchText: onSearchText });
    pvPlantManagementContext.loadPvPLantList({
      ...pvPlantColumnSettings,
      filters: { ...pvPlantColumnSettings.filters, plantSearchText: onSearchText },
    });
  };

  const handleCreatePvPlant = () => {
    history.push(PVPLANT_MANAGEMENT_CREATE_PAGE);
  };
  const currentItems = pvPlantsList?.items || [];

  const toggleExportModal = () => {
    setIsExportModalOpen(!isExpotModalOpen);
  };

  const getExportComponent = () => {
    return <ExportForm onSuccessfulModal={onSuccessfulModal} filterParams={filterParams} />;
  };
  const onSuccessfulModal = () => {
    toggleExportModal();
  };

  const createExport = () => {
    toggleExportModal();
  };

  const handleNameSort = (sortOrder, field) => {
    handleApplyFilter({ ...filterParams, sortingOrder: sortOrder, sortingField: field });
    const updatedSettings = {
      ...pvPlantColumnSettings,
      filters: { ...pvPlantColumnSettings.filters, sortingField: field, sortingOrder: sortOrder },
    };
    handleUpdateViewSettings(updatedSettings);
    pvPlantManagementContext.loadPvPLantList(updatedSettings);
    setPvPlantsColumnSettings(updatedSettings);
  };

  const selectedClientData = clientList[clientList.findIndex((client) => client.value === filterParams.selectedClient)];

  const selectedPlantManager = React.useMemo(() => {
    if (filterParams) {
      return userList?.find((o) => o.value === filterParams.selectedPlantManager);
    }
  }, [filterParams, userList]);

  const columns = React.useMemo(() => {
    if (pvPlantColumnSettings?.fields?.length) {
      return pvPlantColumnSettings.fields
        .filter((cs) => cs.isChecked)
        .map((columnSetting) => {
          return tableColumn(columnSetting);
        });
    }

    return [];
  }, [pvPlantsList.items]);

  const handleClearSearch = async () => {
    setSearchText("");
    handleApplyFilter({ ...filterParams, searchText: "" });
    pvPlantManagementContext.loadPvPLantList({
      ...pvPlantColumnSettings,
      filters: { ...pvPlantColumnSettings.filters, plantSearchText: "" },
    });
    toggle();
  }

 const renderAction = React.useMemo(() => {
   return [
     columnHelper.accessor("id", {
       header: "",
       size: 40,
       enableSorting: false,
       enableResizing: false,
       enablePinning:true,
       cell: (info) => {
         const item = info?.row.original;
         return (
           <ul className="nk-tb-actions gx-1">
             <li>
               <UncontrolledDropdown>
                 <DropdownToggle id="menu-btn-pvplant" tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                   <Icon name="more-h"></Icon>
                 </DropdownToggle>
                 {createPortal(
                   <DropdownMenu right>
                     <ul className="link-list-opt no-bdr">
                       <React.Fragment>
                         <Check permission={["updatePlant"]}>
                             <>
                               <li /* className="nk-tb-action-hidden" */ onClick={() => onEditClick(item.id)}>
                                 <DropdownItem
                                   id="edit-pvplant-btn"
                                   tag="a"
                                   href="#edit"
                                   onClick={(ev) => {
                                     ev.preventDefault();
                                   }}
                                 >
                                   <Icon name="edit-alt-fill"></Icon>
                                   <span>Edit Plant</span>
                                 </DropdownItem>
                               </li>
                               <li className="divider"></li>
                             </>
                          
                           {item.isArchived ? (
                             <React.Fragment>
                               <li onClick={() => handleUnArchive(item.id)}>
                                 <DropdownItem
                                   id="unarchive-pvplant-btn"
                                   tag="a"
                                   href="#unarchive"
                                   onClick={(ev) => {
                                     ev.preventDefault();
                                   }}
                                 >
                                   <Icon name="unarchive"></Icon>
                                   <span>UnArchive Plant</span>
                                 </DropdownItem>
                               </li>
                               <li className="divider"></li>
                             </React.Fragment>
                           ) : (
                             <React.Fragment>
                               <li onClick={() => handleArchive(item.id)}>
                                 <DropdownItem
                                   id="archive-pvplant-btn"
                                   tag="a"
                                   href="#archive"
                                   onClick={(ev) => {
                                     ev.preventDefault();
                                   }}
                                 >
                                   <Icon name="archive"></Icon>
                                   <span>Archive Plant</span>
                                 </DropdownItem>
                               </li>
                             </React.Fragment>
                           )}
                         </Check>
                         <Check permission={["deletePlant"]}>
                           {item.status !== "SUSPENDED" && (
                             <>
                               <li className="divider"></li>
                               <li onClick={() => handleDeleteConfirmation(item.id)}>
                                 <DropdownItem
                                   id="delete-pvplant-btn"
                                   tag="a"
                                   href="#delete"
                                   onClick={(ev) => {
                                     ev.preventDefault();
                                   }}
                                 >
                                   <Icon name="trash"></Icon>
                                   <span>Delete Plant</span>
                                 </DropdownItem>
                               </li>
                             </>
                           )}
                         </Check>
                       </React.Fragment>
                     </ul>
                   </DropdownMenu>,
                   document.body
                 )}
               </UncontrolledDropdown>
             </li>
           </ul>
         );
       },
     }),
   ];
 }, [pvPlantsList.items]);

  const renderTable = React.useMemo(() => {
    const columnMinWidth = [{ field: "name", width: 400 }]
    return (
      <TanstackTable
        dataSource={pvPlantsList.items ?? []}
        _column={[...columns, ...renderAction]}
        columnMinWidth={columnMinWidth}
        columnSettings={pvPlantColumnSettings}
        onColumWidthChanged={(resizedColumn) => {
          const _pvPlantColumnSettings = [...pvPlantColumnSettings.fields].map((ps) => {
            if (ps.field_key === resizedColumn.field_key) {
              return {
                ...ps,
                width: resizedColumn.width,
              };
            } else {
              return ps;
            }
          });
          handleUpdateViewSettings({
            // ...pvPlantColumnSettings,
            entityType: "PV_PLANTS",
            fields: _pvPlantColumnSettings,
          });
          setPvPlantsColumnSettings((prev) => ({ ...prev, fields: _pvPlantColumnSettings }));
        }}
        handleSorting={(sortingParams) => {
          handleNameSort(sortingParams.sortingOrder, sortingParams.sortingField);
        }}
      />
    );
  }, [columns, pvPlantsList.items, pvPlantColumnSettings]);

  async function handleUpdateViewSettings(payload) {
    return await updateViewSettings(payload);
  }

  return (
    <React.Fragment>
      <BlockHead size="sm">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h3" page>
              PV Plants List
            </BlockTitle>
            <BlockDes className="text-soft">
              <p>You have a total of {pvPlantsList?.pagination?.totalCount} PV Plants.</p>
            </BlockDes>
            <div className="d-flex flex-wrap">
              {filterParams.selectedPvPlantStatus && (
                <FilteredByList
                  data={filterParams.selectedPvPlantStatus}
                  title={"Status"}
                  isNotMap={true}
                  isStr={true}
                />
              )}
              {selectedClientData ? (
                <FilteredByList data={selectedClientData?.label} title={"Client"} isNotMap={true} isStr={true} />
              ) : null}
              {selectedPlantManager && (
                <FilteredByList
                  data={selectedPlantManager?.label}
                  title={"Plant Manager"}
                  isNotMap={true}
                  isStr={true}
                />
              )}
              {filterParams.searchText && (
                <FilteredByList data={filterParams.searchText} title="Searched by" isNotMap={true} isStr={true} />
              )}
            </div>
          </BlockHeadContent>
          <BlockHeadContent>
            <div className="toggle-wrap nk-block-tools-toggle">
              <Button
                className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                onClick={() => updateSm(!sm)}
              >
                <Icon name="menu-alt-r"></Icon>
              </Button>
              <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                <ul className="nk-block-tools g-3">
                  <Check permission={["getPlants", "createPlant", "updatePlant", "deletePlant"]}>
                    <li>
                      <Button
                        disabled={pvPlantsList?.pagination?.totalCount === 0}
                        id="export-pvplant"
                        className="btn btn-white btn-outline-light"
                        type="export"
                        isLoading={exportLoading}
                        onClick={createExport}
                      >
                        <Icon name="download-cloud"> </Icon>
                        <span>Export</span>
                      </Button>
                    </li>
                  </Check>
                  <li className="nk-block-tools-opt">
                    <Check permission={["createPlant"]}>
                      <Button id="add-pvplant-btn" color="primary" className="btn-icon" onClick={handleCreatePvPlant}>
                        <Icon name="plus"></Icon>
                      </Button>
                    </Check>
                  </li>
                </ul>
              </div>
            </div>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
      <Block>
        <DataTable className="card-stretch">
          <div className="card-inner position-relative card-tools-toggle ">
            <div className="card-title-group ">
              <div className="card-tools"></div>
              <div className="card-tools mr-n1 ">
                <ul className="btn-toolbar gx-1">
                  <li>
                    <a
                      id="search-pvplant-btn"
                      href="#search"
                      onClick={(ev) => {
                        ev.preventDefault();
                        toggle();
                      }}
                      className="btn btn-icon search-toggle toggle-search"
                    >
                      <Icon name="search"></Icon>
                    </a>
                  </li>
                  <li className="btn-toolbar-sep"></li>
                  <div>
                    <Sidebar toggleState={showFilter}>
                      <div>
                        <PvPlantListFilter />
                      </div>
                    </Sidebar>
                    <div id="pvplant-filter" tag="a" className="btn btn-icon " onClick={() => sfState.toggle()}>
                      {isFilterApplied ? <div className="dot dot-primary"></div> : null}
                      <Icon name="filter-alt"></Icon>
                    </div>
                    {(pvPlantsList?.pagination?.totalCount > 0) &&
                      <div id="pvplant-filter" tag="a" className="btn btn-icon " onClick={() => icamState.on()}>
                        <Icon name="view-list-fill"></Icon>
                      </div>
                    }
                    <ModalViewer
                      title={`${selectedAttachment.plantName} Attachments`}
                      isOpen={isAttachmentModalOpen}
                      size="md"
                      toggleModal={iamoState.toggle}
                      component={
                        Object.keys(selectedAttachment).length ? (
                          <div>
                            {selectedAttachment?.attachments.map((attachment) => {
                              return (
                                <div style={{ display: "flex", alignItems: "center" }}>
                                  <em class="icon ni ni-dot"></em>
                                  <p
                                    style={{ cursor: "pointer" }}
                                    onClick={() => openImageInNewTab(attachment?.files.key)}
                                  >
                                    {attachment?.files?.name}
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          <></>
                        )
                      }
                    />

                    <ModalViewer
                      title="PV Plant Columns"
                      isOpen={isColumnArrangeModalOpen}
                      size="md"
                      toggleModal={icamState.toggle}
                      component={
                        <SortableColumns
                          entityType={"PV_PLANTS"}
                          fields={PLANT_FIELDS}
                          updateViewSettings={async (modifiedSettings) => {
                            const updatedSettings = await handleUpdateViewSettings({
                              ...pvPlantColumnSettings,
                              fields: modifiedSettings,
                            });
                            setPvPlantsColumnSettings((prev) => ({ ...prev, fields: updatedSettings.fields }));
                            await pvPlantManagementContext.loadPvPLantList(updatedSettings);
                          }}
                          closeModal={icamState.off}
                          visibleColumns={pvPlantColumnSettings.fields}
                        />
                      }
                    />
                  </div>
                </ul>
              </div>
            </div>
            <div className={`card-search search-wrap ${!onSearch && "active"}`}>
              <div className="card-body">
                <div className="search-content">
                  <Button
                    id="cancle-pvplant-search"
                    className="search-back btn-icon toggle-search active"
                    onClick={handleClearSearch}
                  >
                    <Icon name="arrow-left"></Icon>
                  </Button>
                  <input
                    id="search-input-pvplant"
                    type="text"
                    className="border-transparent form-focus-none form-control"
                    placeholder="Search by name.enter to search "
                    ref={(el) => {
                      focusSearchRef.current = !onSearch ? el : null;
                    }}
                    value={onSearchText}
                    onChange={(e) => onFilterChange(e)}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        onSearchAction();
                      }
                      if (e.keyCode === 27) {
                        if(onSearchText){
                        setSearchText("")
                        onSearchAction();
                        }
                        handleApplyFilter({ ...filterParams, searchText: "" });
                        toggle();
                      }
                      if (e.keyCode === 88) {
                        setSearchText("");
                      }
                    }}
                  />
                  <Button id="search-text" className="search-submit btn-icon">
                    <Icon name="search" onClick={onSearchAction}></Icon>
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {pvPlantLoading || pvPlantManagementContext.isLoadingFilters ? <Skeleton count={10} className="w-100" /> :
            (pvPlantsList?.pagination?.totalCount > 0) && renderTable}

          <div className="card-inner">
            {pvPlantsList.pagination && pvPlantsList.pagination.totalCount > 0 ? (
              <PaginationComponent
                itemPerPage={pvPlantColumnSettings.filters.size || pagination.size}
                totalItems={pvPlantsList.pagination.totalCount}
                paginate={(page) => {
                  pvPlantManagementContext.loadPvPLantList({
                    ...pvPlantColumnSettings,
                    filters: { ...pvPlantColumnSettings.filters, page },
                  });
                  pvPlantManagementContext.updateShowListPage(page);
                }}
                currentPage={pvPlantsList.pagination.currentPage}
                paginatePage={(size) => {
                  pvPlantManagementContext.updateShowListSize(size);
                  const updatedSetting = {
                    ...pvPlantColumnSettings,
                    filters: { ...pvPlantColumnSettings.filters, size },
                  };
                  handleUpdateViewSettings(updatedSetting);
                  setPvPlantsColumnSettings(updatedSetting);
                  pvPlantManagementContext.loadPvPLantList(updatedSetting);
                }}
              />
            ) : (
              !pvPlantLoading && (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )
            )}
          </div>
        </DataTable>
        <ModalViewer
          title="PV-Plant - Select the Export Fields"
          isOpen={isExpotModalOpen}
          size="md"
          toggleModal={toggleExportModal}
          component={getExportComponent()}
        />
      </Block>
    </React.Fragment>
  );
};
export default PvPlantListCompact;