import { format } from "date-fns";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "jspdf-autotable";
import React, { useContext, useEffect, useState } from "react";
import { ReactFormGenerator } from "react-form-builder2";
import "react-form-builder2/dist/app.css";
import Skeleton from "react-loading-skeleton";
import { useHistory, useParams } from "react-router-dom";
import { Card, Input, Label } from "reactstrap";
import { BlockBetween, BlockHead, BlockHeadContent, Button, Icon } from "../../../components/Component";
import Check from "../../../components/check_roles/check";
import Head from "../../../layout/head/Head";
import { FormManagementContext, withFormManagementProvider } from "../FormManagementProvider";
import { PDFExport } from "@progress/kendo-react-pdf";
import { infoToast, successToast } from "../../../components/toastify/Toastify";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

function ViewForm() {
  const history = useHistory();
  const pdfExportRef = React.useRef(null);

  const {submittedForm, loadFormSubmission } =useContext(FormManagementContext);
  const { formId, id } = useParams();
  const location = useLocation().search;

  const formLayoutData = submittedForm?.forms?.formRevisions?.[0]?.data || []
  const formAnswerData = submittedForm?.rawData || []

  const latestVersion = new URLSearchParams(location).get("version");
  const [sm, updateSm] = useState(false);

    useEffect(() => {
    loadFormSubmission(id, latestVersion)
  },[])

  const handleDownloadPdf = () => {
    infoToast({ description: "Download still in progress. Please wait until the download is complete" });
    if (pdfExportRef.current) {
      pdfExportRef.current.save();
    }
  };

  const createdBy = submittedForm
    ? `${submittedForm?.formUser?.firstName} ${submittedForm?.formUser?.lastName}`
    : "-";
  const createdAt = submittedForm ? format(new Date(submittedForm?.created_at || null), "dd/MM/yyyy HH:mm")
    : "-";
  const formName = submittedForm?.forms?.title || "-";

const formSubmissionData = submittedForm?.data || []


// component for layout less data
const renderLayoutForLayoutLessSubmission = formSubmissionData.map((dataItem, index) => {

  //To check the data is link or not
  const isURL = typeof dataItem.value === "string" && dataItem.value.startsWith("http");
  return (
    <div style={{ marginBottom: "15px", position: "relative" }} key={dataItem.key}>
      <Label className="form-label">{dataItem?.label}</Label>
      <div style={{ position: "relative" }}>
        <Input
          aria-label="hi"
          value={dataItem.value}
          disabled={true}
          style={{ paddingRight: "30px", cursor: isURL ? "pointer" : "default" }}
          />
        {isURL && (
          <a
            href={dataItem.value}
            style={{
              position: "absolute",
              top: "50%",
              left: "10px",
              width: "100%",
              height: "100%",
            }}
          />
        )}
      </div>
    </div>
  );
});



  const renderFormLayoutAndData = () => {
    if (submittedForm?.forms?.formRevisions?.length) {
      return <ReactFormGenerator
        read_only={true}
        download_path=""
        answer_data={formAnswerData}
        data={formLayoutData}
        hide_actions={true}
      />
    } else {
      return renderLayoutForLayoutLessSubmission
    }
  }

  return (
    <React.Fragment>
      <Head title="Brighter App | Form | View" />
      <div style={{ padding: "3%" }}>
        <BlockBetween>
          <div>
            <Button onClick={history.goBack} color="light" outline className="bg-white d-none d-sm-inline-flex">
              <Icon name="arrow-left"></Icon>
              <span>Back</span>
            </Button>
          </div>
          <div style={{ marginTop: "2%" }} className="toggle-wrap nk-block-tools-toggle">
            <Button
              className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
              onClick={() => updateSm(!sm)}
            >
              <Icon name="menu-alt-r"></Icon>
            </Button>
            <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
              <ul className="nk-block-tools g-3">
                <Check permission={["getTickets", "manageTickets"]}>
                  <li>
                    <Button
                      onClick={() => {
                        handleDownloadPdf();
                      }}
                      className={`btn btn-white btn-outline-light ${false ? "disabled" : ""}`}
                      type="export"
                    >
                      <Icon name="download-cloud"> </Icon>
                      <span>Export</span>
                    </Button>
                  </li>
                </Check>
              </ul>
            </div>
          </div>
        </BlockBetween>
        {submittedForm ? <PDFExport fileName={formName} paperSize="A4" margin="2cm" ref={pdfExportRef}>
          <Card id="printableArea" style={{ padding: "2%", marginTop: "10px" }}>
            <BlockHead size="sm">
              <BlockBetween>
             <BlockHeadContent>
                  <div style={{ display: "flex" }}>
                    <span className="profile-ud-label">Form Name :</span>
                    <span className="profile-ud-value">{formName || "-"}</span>
                  </div>
                  <div style={{ display: "flex" }}>
                    <span className="profile-ud-label">Submitted By :</span>
                    <span className="profile-ud-value">{createdBy || "-"}</span>
                  </div>
                  <div style={{ display: "flex" }}>
                    <span className="profile-ud-label">Submitted At :</span>
                    <span className="profile-ud-value">{createdAt || "-"}</span>
                  </div>
                </BlockHeadContent>
                <BlockHeadContent></BlockHeadContent>
              </BlockBetween>
            </BlockHead>
            <hr style={{ marginTop: 0, marginTop: 0, color: "#c8d4e2" }} />
              {renderFormLayoutAndData()}
          </Card>
        </PDFExport>
          :
          <Skeleton width={"100"} count={15} />
        }
      </div>
    </React.Fragment>
  );
}

export default withFormManagementProvider(ViewForm);
