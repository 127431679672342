import { format } from "date-fns";
import React, { useContext, useState } from "react";
import { Col, FormGroup, Spinner, Card } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  Icon,
  Button,
  BlockTitle,
} from "../../../../components/Component";
import { useHistory } from "react-router-dom";
import { MaintenanceContext } from "../../../../providers/Maintenance.provider";
import { CorrectiveContext } from "../CorrectiveProvider";
import Attachments from "../../../../components/attachments/Attachment";
import { TASK } from "../../../../constants/PreferenceKeys";
import { errorToast, successToast } from "../../../../components/toastify/Toastify";
import { confirmationSweetAlert } from "../../../../components/sweetAlert/SweetAlert";
import SubtaskList from "./subtaskComponents/SubtaskList";
import Comments from "../../../../components/comments/Comments";
import ActivityLog from "../../../../components/activity_log/components/ActivityLog";
import ParentTask from "./ParentTaskView";
import SubTransactionList from "./subtaskComponents/subEquipmentList";
import { HOME_PAGE } from "../../../../constants/routes";
import { convertToHoursAndMinutes } from "../../../../utils/DateUtil";

const VIEW = {
  SUB_TASKS: "SUB_TASKS",
  EQUIPMENTS: "EQUIPMENTS",
};

const ViewCorrective = ({ sm, updateSm }) => {
  const history = useHistory();

  const taskContext = useContext(CorrectiveContext);
  const { filterOptions } = useContext(MaintenanceContext);
  const { currentViewTask, deleteTask } = taskContext;
  const [view, setView] = useState(VIEW.SUB_TASKS);

  let engineers = {};
  let plantNames = {};
  let priority = {};
  let status = {};
  let slas = {};
  let assetCategories = {};
  for (let i = 0; i < filterOptions.taskStatuses?.length; i++) {
    status[filterOptions.taskStatuses[i].value] = filterOptions.taskStatuses[i].label;
  }
  for (let i = 0; i < filterOptions.assignedEngineer?.length; i++) {
    engineers[filterOptions.assignedEngineer[i].value] = filterOptions.assignedEngineer[i].label;
  }
  for (let i = 0; i < filterOptions.plants?.length; i++) {
    plantNames[filterOptions.plants[i].value] = filterOptions.plants[i].label;
  }
  for (let i = 0; i < filterOptions.priorityStatuses?.length; i++) {
    priority[filterOptions.priorityStatuses[i].value] = filterOptions.priorityStatuses[i].label;
  }
  for (let i = 0; i < filterOptions.slas?.length; i++) {
    slas[filterOptions.slas[i].value] = filterOptions.slas[i].label;
  }
  for (let i = 0; i < filterOptions.assetCategories?.length; i++) {
    assetCategories[filterOptions.assetCategories[i].value] = filterOptions.assetCategories[i].label;
  }

  if (!currentViewTask) return <Spinner />;
  const handleDeleteCorrective = async (taskId) => {
    deleteTask(taskId)
      .then((res) => {
        history.push(HOME_PAGE);
        successToast({ description: "Corrective task is deleted successfully" });
      })
      .catch((e) => {
        errorToast({ description: "Error happened while deleting Corrective task" });
      });
  };
  const handleDeleteConfirmation = async (taskId) => {
    confirmationSweetAlert({
      id: taskId,
      handleConfirmation: handleDeleteCorrective,
    });
  };
  const renderParentData = (parentId) => {
    taskContext.loadTask(parentId);
  };
  return (
    <>
      <Block className={"bg-white p-4"}>
        <BlockHead size="lg">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h4">Corrective Maintenance</BlockTitle>
              <BlockDes>
                <p>Basic info.</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent className="align-self-end">
              <Button
                color="danger"
                onClick={() => {
                  handleDeleteConfirmation(currentViewTask.id);
                }}
              >
                <Icon name="na" />
                <span>Delete</span>
              </Button>
            </BlockHeadContent>
            <BlockHeadContent className="align-self-start d-lg-none">
              <Button
                className={`toggle btn btn-icon btn-trigger mt-n1 ${sm ? "active" : ""}`}
                onClick={() => updateSm(!sm)}
              >
                <Icon name="menu-alt-r"></Icon>
              </Button>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block size="lg">
          <div className="profile-ud-list">
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Ticket Name</span>
                <span className="profile-ud-value">{currentViewTask.title || "-"}</span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Identifier</span>
                <span className="profile-ud-value">{currentViewTask.identifier || "-"}</span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Status</span>
                <span className="profile-ud-value">{status[currentViewTask.status] || "-"}</span>
              </div>
            </div>

            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Plant Name</span>
                <span className="profile-ud-value">{currentViewTask.plant.name || "-"}</span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Start Date</span>
                <span className="profile-ud-value">
                  {currentViewTask?.startedAt
                    ? format(new Date(currentViewTask?.startedAt || null), "dd/MM/yyyy")
                    : "-"}
                </span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Resolved Date</span>
                <span className="profile-ud-value">
                  {currentViewTask?.resolvedAt
                    ? format(new Date(currentViewTask?.resolvedAt || null), "dd/MM/yyyy")
                    : "-"}
                </span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Priority</span>
                <span className="profile-ud-value"> {priority[currentViewTask.priority] || "-"}</span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">SLA</span>
                <span className="profile-ud-value">{slas[currentViewTask.slaId] || "-"}</span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Asset Category</span>
                <span className="profile-ud-value">{assetCategories[currentViewTask.assetCategoryId] || "-"}</span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Assigned Engineer</span>
                <span className="profile-ud-value">
                  {currentViewTask?.assignedEngineer
                    ?.map((item, key) => {
                      return `${item.firstName} ${item.lastName}`;
                    })
                    .join(", ") || "-"}
                </span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Field Engineer</span>
                <span className="profile-ud-value">
                  {currentViewTask?.fieldEngineer
                    ?.map((item, key) => {
                      return `${item.firstName} ${item.lastName}`;
                    })
                    .join(", ") || "-"}
                </span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Resolved Time (hrs)</span>
                <span className="profile-ud-value">
                  {currentViewTask.resolutionTime ? convertToHoursAndMinutes(currentViewTask.resolutionTime) : "-"}
                </span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Description</span>
                <span className="profile-ud-value">{currentViewTask.description || "-"}</span>
              </div>

            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Comment</span>
                <span className="profile-ud-value">{currentViewTask?.comment || "-"}</span>
              </div>
            </div>
          </div>
          <div className="nk-data data-list">
            {
              <Col lg="12 pt-2">
                <FormGroup className="form-group">
                  <div className="form-control-wrap">
                    <Attachments
                      module={TASK}
                      id={currentViewTask.id}
                      attachments={currentViewTask.attachment}
                      subModule={"view"}
                    />
                  </div>
                </FormGroup>
              </Col>
            }
            {currentViewTask.parentTask && (
              <>
                <Col lg="12" className="mt-2">
                  <Block size="lg" className="mt-2">
                    <Card className="card-bordered p-2">
                      <div>
                        <BlockTitle id="follow-up-task" tag="h6" className="mb-1">
                          Parent Task
                        </BlockTitle>

                        <ParentTask
                          onClick={() => renderParentData(currentViewTask.parentTask?.id)}
                          currentParentTask={currentViewTask.parentTask}
                          parentId={currentViewTask.parentId}
                          taskPlant={currentViewTask.plant}
                        />
                      </div>
                    </Card>
                  </Block>
                </Col>
              </>
            )}
            {currentViewTask && (
              <>
                <Col lg="12" className="mt-2">
                  <Block size="lg" className="mt-2">
                    <Card className="card-bordered p-2">
                      <div>
                        <BlockTitle id="follow-up-task" tag="h6" className="mb-1">
                          Follow-up Task
                        </BlockTitle>

                        <SubtaskList
                          currentUser={currentViewTask.title}
                          parentId={currentViewTask.id}
                          plantId={currentViewTask.plantId}
                        />
                      </div>
                    </Card>
                  </Block>
                  <Block size="lg" className="mt-2">
                    <Card className="card-bordered p-2">
                      <div>
                        <BlockTitle id="equipment-schedule" tag="h6" className="mb-1">
                          Equipment
                        </BlockTitle>
                        <SubTransactionList currentViewTask={currentViewTask} />
                      </div>
                    </Card>
                  </Block>
                </Col>
              </>
            )}
          </div>
          <div className="m-2 ml-3">
            <small class="text-muted">
              CreatedAt: {format(new Date(currentViewTask?.created_at || null), "dd/MM/yyyy HH:mm")}{" "}
            </small>
          </div>
          <div className="m-2 ml-3">
            <small class="text-muted">
              UpdatedAt: {format(new Date(currentViewTask?.updated_at || null), "dd/MM/yyyy HH:mm")}{" "}
            </small>
          </div>
        </Block>
        <Block>
          <ActivityLog id={currentViewTask.id} entityType="TASK" />
        </Block>
      </Block>
    </>
  );
};
export default ViewCorrective;
