import * as yup from "yup";

const createSchema = yup.object({
  ownership: yup.string().default("PLANT"),
  description: yup.string().trim().nullable(),
  category: yup.string().required(),
  equipmentName: yup.string().required(),
  isKeyComponent: yup.boolean().required().default(false),
  plantId: yup
    .number()
    .notRequired()
    .nullable()
    .when("ownership", {
      is: "PLANT",
      then: yup.number().required("This field is required"),
    }),
  contractualQuantity: yup
    .number()
    .typeError("Specify a Number")
    .integer("Invalid Number")
    .max(1000000, "Max value 1000000.")
    .default(0),
  quantity: yup
    .number()
    .typeError("Specify a Number")
    .integer("Invalid Number")
    .default(0)
    .when("ownership", {
      is: "BGE",
      then: yup
        .number()
        .typeError("Specify a Number")
        .integer("Invalid Number")
        .max(1000000, "Max value 1000000.")
        .default(0),
    }),
  partNumber: yup.string().when("ownership", {
    is: "PLANT",
    then: yup.string(),
  }),
  // maintenanceTicket: yup.string().required(),

  installedQuantity: yup
    .number()
    .typeError("Specify a Number")
    .min(0, "Min value 0.")
    .max(1000000, "Max value 1000000."),
  spareQuantity: yup.number().typeError("Specify a Number").min(0, "Min value 0.").max(1000000, "Max value 1000000."),
  bgeWarehouseQuantity: yup
    .number()
    .typeError("Specify a Number")
    .min(0, "Min value 0.")
    .max(1000000, "Max value 1000000."),
  externalWarehouseQuantity: yup
    .number()
    .typeError("Specify a Number")
    .min(0, "Min value 0.")
    .max(1000000, "Max value 1000000."),
  supplierPremisesQuantity: yup
    .number()
    .typeError("Specify a Number")
    .min(0, "Min value 0.")
    .max(1000000, "Max value 1000000."),
});

const transactionCreateSchema = yup.object({
  recordType: yup.string().required(),
  recordDate: yup.date().required().default(new Date()),
  quantity: yup
    .number()
    .required("This field is required")
    .typeError("Specify a Number")
    .min(1, "Min value 1.")
    .integer("Invalid Number")
    // .max(10000, "Max value 10000.")
    .max(createSchema.installedQuantity, "nnn")
    .default(0),
  cause: yup.string(),
  description: yup.string(),
  fromWarehouse: yup.string(),
  toWarehouse: yup.string(),
  taskId: yup.string(),
});
export { createSchema, transactionCreateSchema };
