import { format } from "date-fns";
import React, { useContext } from "react";
import { Card, Col, FormGroup, Spinner } from "reactstrap";
import { Block, BlockHead, BlockHeadContent, BlockTitle, Button, Icon } from "../../../../components/Component";
import ActivityLog from "../../../../components/activity_log/components/ActivityLog";
import Attachments from "../../../../components/attachments/Attachment";
import Check from "../../../../components/check_roles/check";
import { confirmationSweetAlert } from "../../../../components/sweetAlert/SweetAlert";
import { errorToast, successToast } from "../../../../components/toastify/Toastify";
import { TASK } from "../../../../constants/PreferenceKeys";
import Head from "../../../../layout/head/Head";
import { MaintenanceContext } from "../../../../providers/Maintenance.provider";
import SubTransactionList from "../../corrective/components/subtaskComponents/subEquipmentList";
import { RemedialContext } from "../RemedialProvider";

const RemedialView = ({ setIsOpen, editTask, plantId }) => {
  const taskContext = useContext(RemedialContext);
  const { filterOptions } = useContext(MaintenanceContext);
  const { currentViewTask, deleteTask } = taskContext;

  let engineers = {};
  let plantNames = {};
  let priority = {};
  let status = {};
  let assetCategories = {};

  for (let i = 0; i < filterOptions.taskStatuses?.length; i++) {
    status[filterOptions.taskStatuses[i].value] = filterOptions.taskStatuses[i].label;
  }
  for (let i = 0; i < filterOptions.assignedEngineer?.length; i++) {
    engineers[filterOptions.assignedEngineer[i].value] = filterOptions.assignedEngineer[i].label;
  }
  for (let i = 0; i < filterOptions.plants?.length; i++) {
    plantNames[filterOptions.plants[i].value] = filterOptions.plants[i].label;
  }
  for (let i = 0; i < filterOptions.priorityStatuses?.length; i++) {
    priority[filterOptions.priorityStatuses[i].value] = filterOptions.priorityStatuses[i].label;
  }
  for (let i = 0; i < filterOptions.assetCategories?.length; i++) {
    assetCategories[filterOptions.assetCategories[i].value] = filterOptions.assetCategories[i].label;
  }
  if (!currentViewTask) return <Spinner />;

  const handleDeleteRemedial = async (taskId) => {
    deleteTask(taskId, plantId)
      .then((res) => {
        successToast({ description: "Remedial task is deleted successfully" });
        setIsOpen(false);
      })
      .catch((e) => {
        errorToast({ description: "Error happened while deleting Remedial task" });
      });
  };

  const handleDeleteConfirmation = async (taskId) => {
    confirmationSweetAlert({
      id: taskId,
      handleConfirmation: handleDeleteRemedial,
    });
  };

  return (
    <>
      <BlockHead size="md">
        <div className="d-md-flex justify-content-md-between">
          <BlockHeadContent className="align-self-end">
          <BlockTitle tag="h5">{currentViewTask?.title}</BlockTitle>
            <BlockTitle tag="h5">{currentViewTask.identifier}</BlockTitle>
           </BlockHeadContent>
           <Check permission={["manageTickets"]}>
            <BlockHeadContent className="align-self-end">
              <Button
                id="delete-remedial"
                color="danger"
                onClick={() => {
                  handleDeleteConfirmation(currentViewTask.id);
                }}
              >
                <Icon name="na" />
                <span>Delete</span>
              </Button>
              {(currentViewTask?.plant?.status && currentViewTask.plant.status === "ACTIVE")
                &&
                <Button
                  id="edit-remedial"
                  color="primary"
                  className={"ml-2"}
                  onClick={() => {
                    editTask(currentViewTask.id);
                  }}
                >
                  <Icon name="edit" />
                  <span>Edit</span>
                </Button>
              }
            </BlockHeadContent>
          </Check>
        </div>
      </BlockHead>
      <div className="overflow-auto h-max-450px">
        <Head title="Brighter App | Remedial | View" />

        <Block className={"bg-white overflow-auto"}>
          <Block>
            <div className="nk-data data-list">
              <div className="data-item">
                <div className="data-col">
                  <span className="data-label">Ticket Name</span>
                  <span className="data-value">{currentViewTask.title || "-"} </span>
                </div>
                <div className="data-col data-col-end"></div>
              </div>
              <div className="data-item">
                <div className="data-col">
                  <span className="data-label">Status</span>
                  <span className="data-value">{status[currentViewTask.status] || "-"}</span>
                </div>
                <div className="data-col data-col-end"></div>
              </div>
              <div className="data-item">
                <div className="data-col">
                  <span className="data-label">Plant Name</span>
                  <span className="data-value">{plantNames[currentViewTask.plantId] || "-"}</span>
                </div>
                <div className="data-col data-col-end"></div>
              </div>
              <div className="data-item">
                <div className="data-col">
                  <span className="data-label">Start Date</span>
                  <span className="data-value">
                    {currentViewTask?.startedAt
                      ? format(new Date(currentViewTask?.startedAt || null), "dd/MM/yyyy")
                      : "-"}
                  </span>
                </div>
                <div className="data-col data-col-end"></div>
              </div>
              <div className="data-item">
                <div className="data-col">
                  <span className="data-label">Resolved Date</span>
                  <span className="data-value">
                    {currentViewTask?.resolvedAt
                      ? format(new Date(currentViewTask?.resolvedAt || null), "dd/MM/yyyy")
                      : "-"}
                  </span>
                </div>
                <div className="data-col data-col-end"></div>
              </div>
              <div className="data-item">
                <div className="data-col">
                  <span className="data-label">Priority</span>
                  <span className="data-value">{priority[currentViewTask.priority] || "-"}</span>
                </div>
                <div className="data-col data-col-end"></div>
              </div>
              <div className="data-item">
                <div className="data-col">
                  <span className="data-label">Asset Category</span>
                  <span className="data-value">{assetCategories[currentViewTask.assetCategoryId] || "-"}</span>
                </div>
                <div className="data-col data-col-end"></div>
              </div>
              <div className="data-item">
                <div className="data-col">
                  <span className="data-label">Assigned Engineer</span>
                  <span className="data-value">
                    {currentViewTask?.assignedEngineer
                      ?.map((item, key) => {
                        return `${item.firstName} ${item.lastName}`;
                      })
                      .join(", ") || "-"}
                  </span>
                </div>
                <div className="data-col data-col-end"></div>
              </div>
              <div className="data-item">
                <div className="data-col">
                  <span className="data-label">Field Engineer</span>
                  <span className="data-value">
                    {currentViewTask?.fieldEngineer
                      ?.map((item, key) => {
                        return `${item.firstName} ${item.lastName}`;
                      })
                      .join(", ") || "-"}
                  </span>
                </div>
                <div className="data-col data-col-end"></div>
              </div>
              <div className="data-item">
                <div className="data-col">
                  <span className="data-label">Description</span>
                  <span dangerouslySetInnerHTML={{ __html: currentViewTask?.description }} className="data-value text-break test-editor" />
                </div>
                <div className="data-col data-col-end"></div>
              </div>
              <div className="data-item">
                <div className="data-col">
                  <span className="data-label">Comment</span>
                  <span dangerouslySetInnerHTML={{ __html: currentViewTask?.comment }} className="data-value text-break test-editor" />
                </div>
                <div className="data-col data-col-end"></div>
              </div>
              {
                <Col lg="12 pt-2">
                  <FormGroup className="form-group">
                    <div className="form-control-wrap">
                      <Attachments module={TASK} id={currentViewTask.id} attachments={currentViewTask.attachment} />
                    </div>
                  </FormGroup>
                </Col>
              }
            </div>
            <div className="m-2 ml-3">
              <small class="text-muted">
                CreatedAt: {format(new Date(currentViewTask?.created_at || null), "dd/MM/yyyy HH:mm")}{" "}
              </small>
            </div>
            <div className="m-2 ml-3">
              <small class="text-muted">
                UpdatedAt: {format(new Date(currentViewTask?.updated_at || null), "dd/MM/yyyy HH:mm")}{" "}
              </small>
            </div>
          </Block>
          <Block size="lg" className="mt-2">
            <Card className="card-bordered p-2">
              <div>
                <BlockTitle id="equipment-schedule" tag="h6" className="mb-1">
                  Equipment
                </BlockTitle>
                <SubTransactionList currentViewTask={currentViewTask} />
              </div>
            </Card>
          </Block>
          <Block>
            <ActivityLog id={currentViewTask.id} entityType="TASK" />
          </Block>
        </Block>
      </div>
    </>
  );
};
export default RemedialView;
