import React from "react";
import useBoolean from "../../../hooks/useBoolean";
import {
  deleteTaskAPI,
  getCorrectiveList,
  getTaskAPI,
  updateTaskAPI,
  getAllCorrectivetasks,
  unarchiveTaskAPI,
  archiveTaskAPI,
} from "./CorrectiveRepository";
import { getTableViewSettings } from "../../../repositories/ViewRepository";
import IndexCacheService from "../../../services/IndexCacheService";

const initialPaginationState = {
  currentPage: 1,
  pageSize: 10,
  totalCount: 0,
  totalPages: 1,
};

export const CorrectiveContext = React.createContext({
  pagination: { ...initialPaginationState },
  loadCorrectiveTasks: async (settings, filterParams = {}) => { },
});

const initialFilterParams = {
  selectedTaskStatus: [],
  selectedAssignedEngineer: [],
  selectedPlant: [],
  isArchiveSelected: false,
  searchText: "",
  startDate: "",
  resolvedDate: "",
  sortingOrder: "",
  sortingField: "",
  table: "",
  selectedSLA: "",
  page: 1,
  size: 10,
};

export const CorrectiveProvider = (props) => {
  const [correctiveList, setCorrectiveList] = React.useState([]);
  const [pagination, setPagination] = React.useState(Object.assign({}, initialPaginationState));
  const [filterParams, setFilterParams] = React.useState(Object.assign({}, initialFilterParams));
  const [currentViewTask, setCurrentViewTask] = React.useState();
  const [correctivetasks, setCorrectiveTasks] = React.useState([]);
  const [tasksColumnSettings, setTasksColumnSettings] = React.useState([]);

  //Loaders
  const [correctiveLoading, correctiveState] = useBoolean(false);
  const [loadingTasks, tlState] = useBoolean(false);
  const [dashboardFilterStatus, setDashboardFilterStatus] = useBoolean(false);
  const [updateLoading, upState] = useBoolean(false);

  //Close Tab
  const [showFilter, sfState] = useBoolean(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isLoadingFilters, ilfState] = useBoolean(false);

  const getCorrectiveListFilterParams = () => {
    const queryParams = {
      page: filterParams.page,
      size: filterParams.size,
    };

    const {
      selectedTaskStatus,
      isArchiveSelected,
      searchText,
      selectedAssignedEngineer,
      resolvedDate,
      startDate,
      selectedPlant,
      sortingField,
      sortingOrder,
      selectedSLA,
      // table,
    } = filterParams;

    if (selectedTaskStatus.length) queryParams["taskStatus"] = selectedTaskStatus;
    if (selectedPlant.length) queryParams["taskPlant"] = selectedPlant;
    if (selectedAssignedEngineer.length) queryParams["taskAssignedEngineer"] = selectedAssignedEngineer;
    if (searchText) queryParams["taskText"] = searchText;
    if (isArchiveSelected != null) queryParams["taskArchived"] = isArchiveSelected;
    if (startDate) queryParams["taskStartDate"] = startDate;
    if (resolvedDate) queryParams["taskResolvedDate"] = resolvedDate;
    if (selectedSLA) queryParams["taskSLA"] = selectedSLA;
    if (sortingField) queryParams["sortingField"] = sortingField;
    if (sortingOrder) queryParams["sortingOrder"] = sortingOrder;
    // if (table) queryParams["table"] = table;
    return queryParams;
  };

  const getTableSettings = async (entityType, useSettingsFilter = true) => {
    try {
      ilfState.on()
      const settings = await getTableViewSettings(entityType);
      setTasksColumnSettings(settings)
      const constructFilterParams = {
        selectedTaskStatus: settings.filters.taskStatus ?? [],
        isArchiveSelected: settings.filters.taskArchived ?? false,
        sortingOrder: settings.filters.sortingOrder ?? "",
        sortingField: settings.filters.sortingField ?? "",
        selectedPlant: settings.filters.taskPlant ?? [],
        selectedAssignedEngineer: settings.filters.taskAssignedEngineer ?? [],
        startDate: settings.filters.taskStartDate ?? "",
        resolvedDate: settings.filters.taskResolvedDate ?? "",
        selectedSLA: settings.filters.taskSLA ?? "",
        page: settings.filters.page ?? 1,
        size: settings.filters.size ?? 10
      };
      if (useSettingsFilter) {
        handleApplyFilter(constructFilterParams)
      }
      return settings;
    } catch (error) {
      throw error;
    } finally {
      ilfState.off()
    }
  };

  const loadCorrectiveList = async (settings, plantId, status, dashboard) => {
    correctiveState.on();
    if (status) {
      handleApplyFilter({ ...filterParams, selectedTaskStatus: [status] });
    }
    try {
      const filterParams = getCorrectiveListFilterParams();
      if (status) {
        filterParams["taskStatus"] = status;
      }
      if (plantId) {
        filterParams["taskPlant"] = plantId;
      }
      const attributes = settings?.fields?.filter((f) => f.isChecked).map((field) => field.field_key);
      let queryParams
      if (dashboard) {
        queryParams = filterParams
      }
      else {
        queryParams = Object.assign(filterParams, {
          ...settings.filters,
        });
      }
      const response = await getCorrectiveList(queryParams, { attributes });
      setCorrectiveList(response.correctiveList);
    } catch (e) {
      // CAPTURE EXCEPTION
      throw e;
    } finally {
      correctiveState.off();
    }
  };

  const loadCorrectiveTasks = async (nextFilterParams = {}) => {
    const settings = { ...tasksColumnSettings };

    correctiveState.on();
    setCorrectiveList([]);

    const { plantId } = nextFilterParams;
    delete nextFilterParams["plantId"];

    try {
      const filterParams = { ...getCorrectiveListFilterParams(), ...nextFilterParams };
      if (plantId) {
        filterParams["taskPlant"] = plantId;
      }
      const attributes = settings?.fields?.filter((f) => f.isChecked).map((field) => field.field_key);
      let queryParams = {
        ...filterParams,
      };
      const response = await getCorrectiveList(queryParams, { attributes });
      setCorrectiveList(response.correctiveList);
      setPagination({
        currentPage: response.correctiveList.pagination.currentPage,
        pageSize: response.correctiveList.pagination.pageSize,
        totalCount: response.correctiveList.pagination.totalCount,
        totalPages: response.correctiveList.pagination.totalPages,
      });
    } catch (e) {
      // CAPTURE EXCEPTION
      throw e;
    } finally {
      correctiveState.off();
    }
  };


  // VIEW TASK
  const loadTask = async (taskId) => {
    try {
      const response = await getTaskAPI(taskId);
      setCurrentViewTask(response.task);
      return response;
    } catch (e) {
      throw e;
    } finally {
      //
    }
  };
  // UPDATE TASK
  const updateTask = async (taskId, updateParams) => {
    upState.on();
    try {
      const response = await updateTaskAPI(taskId, updateParams);
      await IndexCacheService.clearCache()
      setCurrentViewTask(response.task);
    } catch (e) {
      throw e;
    } finally {
      upState.off();
    }
  };

  //to get the setting attributes
  const getsettings = async (plantId) => {
    let settings
    if (plantId) {
      settings = await getTableSettings({
        entityType: "CORRECTIVE_PLANT",
      });
      if (plantId) {
        filterParams["taskPlant"] = plantId;
      }
    }
    else {
      settings = await getTableSettings({
        entityType: "CORRECTIVE_MAINTENANCE",
      });
    }
    const attributes = settings?.fields?.filter((f) => f.isChecked).map((field) => field.field_key);
    const queryParams = Object.assign(filterParams, {
      ...settings.filters,
    });
    const response = await getCorrectiveList(queryParams, { attributes })
    setCorrectiveList(response.correctiveList);
  }

  // DELETE TASK
  const deleteTask = async (taskId, plantId) => {
    try {
      await deleteTaskAPI(taskId);
      await IndexCacheService.clearCache()
      loadCorrectiveTasks({plantId})
    } catch (e) {
      throw e;
    } finally {
      //
    }
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  //APPLY FILTER
  const checkIsFilterApplied = () => {
    if (
      filterParams.isArchiveSelected ||
      filterParams.selectedTaskStatus.length !== 0 ||
      filterParams.selectedPlant.length !== 0 ||
      filterParams.selectedAssignedEngineer.length !== 0 ||
      filterParams.startDate ||
      filterParams.resolvedDate
    ) {
      return true;
    } else return false;
  };
  const isFilterApplied = checkIsFilterApplied();
  const subCheckIsFilterApplied = () => {
    if (
      filterParams.isArchiveSelected ||
      filterParams.selectedTaskStatus.length !== 0 ||
      filterParams.selectedAssignedEngineer.length !== 0 ||
      filterParams.startDate ||
      filterParams.resolvedDate
    ) {
      return true;
    } else {
      return false;
    }
  };
  const isSubFilterApplied = subCheckIsFilterApplied();

  const loadAllCorrectiveTasks = async (filter) => {
    tlState.on();
    try {
      const response = await getAllCorrectivetasks(filter);
      setCorrectiveTasks(response);
    } catch (e) {
      // HANDLE ERROR
    } finally {
      tlState.off();
    }
  };
  //ARCHIVE TASK
  const archiveTask = async (taskId, plantId) => {
    try {
      tlState.on();
      await archiveTaskAPI(taskId);
      getsettings(plantId)
    } catch (e) {
      throw e;
    } finally {
      tlState.off();
    }
  };

  //UNARCHIVE TASK
  const unarchiveTask = async (taskId, plantId) => {
    try {
      tlState.on();
      await unarchiveTaskAPI(taskId);
      getsettings(plantId)
    } catch (e) {
      throw e;
    } finally {
      tlState.off();
    }
  };

  const handleApplyFilter = (selectedFilters) => {
    setFilterParams(selectedFilters);
  };

  const resetClientListFilter = (params) =>{
    const { page, size } = params
    setFilterParams(Object.assign({}, {...initialFilterParams, page, size}));
  }
  const mContext = {
    checkIsFilterApplied,
    showFilter,
    updateLoading,
    sfState,
    correctiveList,
    pagination,
    loadCorrectiveList,
    loadCorrectiveTasks,
    toggleModal,
    isOpen,
    setIsOpen,
    isFilterApplied,
    correctiveLoading,
    filterParams,
    isSubFilterApplied,
    subCheckIsFilterApplied,
    loadTask,
    handleApplyFilter,
    resetClientListFilter,
    currentViewTask,
    updateTask,
    deleteTask,
    correctivetasks,
    loadingTasks,
    archiveTask,
    unarchiveTask,
    loadAllCorrectiveTasks,
    dashboardFilterStatus,
    setDashboardFilterStatus,
    setCorrectiveList,
    getTableSettings,
    isLoadingFilters,
    initialFilterParams,
    setTasksColumnSettings,
    tasksColumnSettings,
    correctiveState,
  };

  return <CorrectiveContext.Provider value={mContext}>{props.children}</CorrectiveContext.Provider>;
};

export const withCorrectiveProvider = (Container, containerProps) => (props) =>
(
  <CorrectiveProvider>
    <Container {...containerProps} {...props} />
  </CorrectiveProvider>
);
