/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";
import { useContext, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import Skeleton from "react-loading-skeleton";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import * as yup from "yup";
import { Block, BlockTitle, BlockHeadContent, BlockHead, Button, Icon, PreviewCard, RSelect } from "../../../../../components/Component";
import ActivityLog from "../../../../../components/activity_log/components/ActivityLog";
import Attachments from "../../../../../components/attachments/Attachment";
import { errorToast, successToast } from "../../../../../components/toastify/Toastify";
import { TASK } from "../../../../../constants/PreferenceKeys";
import Head from "../../../../../layout/head/Head";
import { ActivityLogContext } from "../../../../../providers/ActivtiyLogProvider";
import { sortOption } from "../../../../../utils/Utils";
import { MODE, MONTH_OPTIONS, PRIORITY, STATUS } from "../../constants";
import { PreventativeTaskContext } from "../PreventativeTaskProvider";
import { getPreventativeTask, updatePreventativeTask } from "../PreventativeTaskRepository";
import Check from "../../../../../components/check_roles/check";
import { format } from "date-fns";
import { TextEditor } from "../../../../../components/ReactQuillEditor";

const PreventativeTaskForm = ({ mode, setMode, currentTaskId, handleDeleteConfirmation, setIsOpen, plantId: currentPlantId }) => {
  const createSchema = yup.object({
    title: yup.string().required().default(""),
    priority: yup.string().required(),
    plantId: yup.number().required().default(""),
    status: yup.string().trim().required().default(""),
    startedAt: yup.date().notRequired().nullable().default(""),
    resolvedAt: yup.date().min(yup.ref("startedAt")).notRequired().nullable(),
    assignedToIds: yup.array().of(yup.number()).required().default([]),
    fieldEngineerIds: yup.array().of(yup.number()).notRequired().nullable(),
    months: yup.array().of(yup.number()).required().default([]),
    description: yup.string().trim().notRequired().nullable(),
    comment: yup.string().trim().notRequired().nullable(),
    slaId: yup.number().notRequired().default(),
    assetCategoryId: yup.number().notRequired().default(),
    code: yup.string().required().default(""),
    frequency: yup.string().trim().default("").nullable(),
  });
  const [currentTask, setCurrentTask] = useState({});
  const [plantId, setPlantId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const preventativeContext = useContext(PreventativeTaskContext);
  const { plants, engineers, slas, assetCategories, loadPvPlantList, loadPreventativeTasks, tasksColumnSettings } = preventativeContext;
  const { loadActivityLogList, pagination } = useContext(ActivityLogContext);

  const userFormRef = useForm({
    resolver: yupResolver(createSchema),
  });
  const {
    setValue,
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = userFormRef;

  const loadCurrentTask = async () => {
    try {
      const response = await getPreventativeTask(currentTaskId);
      if (Object.keys(response).length) {
        setCurrentTask(response.task);
        setPlantId(response.task.plantId);
        reset({
          ...response.task,
          startedAt: response.task.startedAt === null ? "" : new Date(response.task.startedAt),
          resolvedAt: response.task.resolvedAt !== null ? new Date(response.task.resolvedAt) : null,
        });
      }
      setIsLoading(false);
    } catch (err) { }
  };

  const logActivity = () => {
    const filter = { entityId: currentTaskId, entityType: "TASK", ...pagination };
    loadActivityLogList(filter);
  };
  useEffect(() => {
    if (currentTaskId) {
      loadCurrentTask();
      logActivity();
    }
  }, [currentTaskId, pagination]);

  const handleFormSubmit = async (formData) => {
    try {
      setIsLoading(true);

      if (formData.resolvedAt) {
        // Only date is required so removed timestamp which double converts to UTC;
        formData.resolvedAt = format(formData.resolvedAt, 'yyyy-MM-dd')
      }
      await updatePreventativeTask(currentTaskId, formData).then(() => {
        logActivity();
        loadPreventativeTasks({ plantId: currentPlantId })
      });
      successToast({ description: "Successfully updated" });

      setIsLoading(false);
      setIsOpen(false);
    } catch (err) {
      errorToast({ description: "Error happened while updating Preventative Task" });
      setIsLoading(false);
    }
  };

  let engineers_options = [];
  plants?.map((item) => {
    if (item.value === plantId) {
      for (let i = 0; i < engineers.length; i++) {
        if (item.fieldEngineerIds?.includes(engineers[i].value)) {
          engineers_options.push(engineers[i]);
        }
      }
    }
  });
  const formClass = classNames({
    "form-validate": true,
    "is-alter": true,
  });
  return (
    <>
      {mode === MODE.VIEW ? (
        <BlockHead size="md">
          <div className="d-md-flex justify-content-md-between mb-2">
            <BlockHeadContent className="align-self-end">
                <BlockTitle tag="h5">{currentTask?.title}</BlockTitle>
                <BlockTitle tag="h5">{currentTask.identifier}</BlockTitle>
            </BlockHeadContent>
            <Check permission={["manageTickets"]}>
              <BlockHeadContent className="align-self-end">
                <div>
                  <Button color="danger" size="md" onClick={() => handleDeleteConfirmation(currentTask.id)}>
                    <Icon name="na" />
                    <span>Delete</span>
                  </Button>
                  {(currentTask?.plant?.status && currentTask.plant.status === "ACTIVE")
                    &&
                    <Button className="ml-3" color="primary" size="md" onClick={() => setMode(MODE.EDIT)}>
                      <Icon name="edit" />
                      <span>Edit</span>
                    </Button>
                  }
                </div>
              </BlockHeadContent>
            </Check>
          </div>
        </BlockHead>
      ) : null}
      <div className="overflow-auto h-max-450px">
        <Head title={mode === MODE.VIEW ? "Brighter App | Preventative | View" : "Brighter App | Preventative | Edit"} />
        <Block size="lg">
          <PreviewCard>

            {mode != "EDIT" || currentTask ? (
              <Form className={formClass} onSubmit={(e) => e.preventDefault()}>
                <Row className="g-4">
                  <Col lg="6">
                    <FormGroup>
                      <Label className="form-label" htmlFor="fv-full-name">
                        <span style={{ color: "indianred" }}>&#42;</span>Task Name
                      </Label>
                      <div className="form-control-wrap">
                        <input
                          {...register("title")}
                          type="text"
                          name="title"
                          className="form-control"
                          disabled={mode === MODE.VIEW}
                        />
                        {errors.title && (
                          <span className="invalid" style={{ color: "red" }}>
                            Title is required
                          </span>
                        )}
                      </div>
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <Label className="form-label" htmlFor="fv-full-name">
                        <span style={{ color: "indianred" }}>&#42;</span>Priority
                      </Label>
                      <Controller
                        control={control}
                        name="priority"
                        rules={{ required: true }}
                        render={({ field, ref }) => {
                          const selectedValue = PRIORITY.find((e) => e.value === field.value);
                          return (
                            <RSelect
                              {...field}
                              ref={ref}
                              value={selectedValue}
                              options={PRIORITY}
                              onChange={(o) => setValue("priority", o.value)}
                              isDisabled={mode === MODE.VIEW}
                            />
                          );
                        }}
                      />
                      {errors?.priority && <span className="invalid">Priority is required</span>}
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <Label className="form-label" htmlFor="fv-full-name">
                        SLA
                      </Label>
                      <Controller
                        control={control}
                        name="slaId"
                        rules={{ required: true }}
                        render={({ field, ref }) => {
                          //slas
                          const options = slas;
                          const selectedValue = options.find((e) => e.value === field.value);

                          return (
                            <RSelect
                              {...field}
                              ref={ref}
                              value={selectedValue}
                              options={options}
                              onChange={(o) => setValue("slaId", o.value)}
                              isDisabled={mode === MODE.VIEW}
                            />
                          );
                        }}
                      />
                      {errors?.slaId && <span className="invalid">SLA is required</span>}
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup className="form-group">
                      <label className="form-label">
                        <span style={{ color: "indianred" }}>&#42;</span>Status
                      </label>
                      <Controller
                        control={control}
                        name="status"
                        rules={{ required: true }}
                        render={({ field, ref }) => {
                          const options = STATUS;
                          const selectedValue = options.find((e) => e.value === field.value);

                          return (
                            <RSelect
                              {...field}
                              ref={ref}
                              value={selectedValue}
                              options={options}
                              onChange={(o) => setValue("status", o.value)}
                              isDisabled={mode === MODE.VIEW}
                            />
                          );
                        }}
                      />
                      {errors?.status && <span className="invalid">Status is required</span>}
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <Label className="form-label" htmlFor="fv-full-name">
                        <span style={{ color: "indianred" }}>&#42;</span>Plant Name
                      </Label>
                      <Controller
                        control={control}
                        name="plantId"
                        rules={{ required: true }}
                        render={({ field, ref }) => {
                          const options = sortOption(plants);
                          const selectedValue = options.find((e) => e.value === field.value);
                          return (
                            <RSelect
                              {...field}
                              ref={ref}
                              value={selectedValue}
                              options={options}
                              onChange={(o) => {
                                setValue("plantId", o.value);
                                setValue("fieldEngineerIds", []);
                                setValue("assignedToIds", []);
                                setPlantId(o.value);
                              }}
                              isDisabled
                            />
                          );
                        }}
                      />
                      {errors?.plantId && <span className="invalid">Plant name is required</span>}
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup className="form-group">
                      <label className="form-label">Field Engineer</label>
                      <Controller
                        control={control}
                        name="fieldEngineerIds"
                        rules={{ required: true }}
                        render={({ field, ref }) => {
                          const options = sortOption(engineers_options);
                          const selectedValue = options.filter((e) => field.value && field.value.includes(e.value));

                          return (
                            <RSelect
                              {...field}
                              ref={ref}
                              value={selectedValue}
                              isMulti
                              options={options}
                              onChange={(o) =>
                                setValue(
                                  "fieldEngineerIds",
                                  o.map((item) => item.value)
                                )
                              }
                              isDisabled={mode === MODE.VIEW}
                            />
                          );
                        }}
                      />
                      {errors?.fieldEngineerIds && (
                        <span style={{ color: "indianred" }} className="invalid">
                          Field Engineer is required
                        </span>
                      )}
                    </FormGroup>
                  </Col>

                  <Col lg="6">
                    <FormGroup>
                      <Label className="form-label" htmlFor="fv-full-name">
                        Start Date
                      </Label>
                      <div className="form-control-wrap">
                        <Controller
                          control={control}
                          name="startedAt"
                          render={({ field }) => {
                            return (
                              <DatePicker
                                ref={field.ref}
                                dateFormat="dd/MM/yyyy"
                                selected={field.value}
                                onChange={(date) => field.onChange(date)}
                                className="form-control date-picker"
                                disabled={mode === MODE.VIEW}
                              />
                            );
                          }}
                        />
                      </div>
                      {errors?.startedAt && (
                        <span style={{ color: "indianred" }} className="invalid">
                          Start Date is required
                        </span>
                      )}
                    </FormGroup>
                  </Col>

                  <Col lg="6">
                    <FormGroup className="form-group">
                      <label className="form-label">
                        <span style={{ color: "indianred" }}>&#42;</span>Assigned To
                      </label>
                      <Controller
                        control={control}
                        name="assignedToIds"
                        rules={{ required: true }}
                        render={({ field, ref }) => {
                          const options = engineers_options;
                          const selectedValue = options.filter((e) => field.value && field.value.includes(e.value));

                          return (
                            <RSelect
                              {...field}
                              ref={ref}
                              value={selectedValue}
                              isMulti
                              options={sortOption(options)}
                              onChange={(o) => {
                                setValue(
                                  "assignedToIds",
                                  o.map((item) => item.value)
                                );
                              }}
                              isDisabled={mode === MODE.VIEW}
                            />
                          );
                        }}
                      />
                      {errors?.assignedToIds && (
                        <span style={{ color: "indianred" }} className="invalid">
                          Assigned To is required
                        </span>
                      )}
                    </FormGroup>
                  </Col>

                  <Col lg="6">
                    <FormGroup className="form-group">
                      <label className="form-label">Resolved Date</label>
                      <div className="form-control-wrap">
                        <Controller
                          control={control}
                          name="resolvedAt"
                          render={({ field, ref }) => {
                            return (
                              <DatePicker
                                ref={field.ref}
                                dateFormat="dd/MM/yyyy"
                                selected={field.value}
                                onChange={(date) => field.onChange(date)}
                                className="form-control date-picker"
                                disabled={mode === MODE.VIEW}
                              />
                            );
                          }}
                        />
                      </div>
                      {errors?.resolvedAt && (
                        <span className="invalid" style={{ color: "red" }}>
                          {errors?.resolvedAt.message.includes("later") &&
                            `Resolved Date should be greater than Start Date`}
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup className="form-group">
                      <label className="form-label">Asset Category</label>
                      <Controller
                        control={control}
                        name="assetCategoryId"
                        rules={{ required: true }}
                        render={({ field, ref }) => {
                          //asset categories
                          const options = assetCategories;
                          const selectedValue = options.find((e) => e.value === field.value);

                          return (
                            <RSelect
                              {...field}
                              ref={ref}
                              value={selectedValue}
                              options={sortOption(options)}
                              onChange={(o) => setValue("assetCategoryId", o.value)}
                              isDisabled={mode === MODE.VIEW}
                            />
                          );
                        }}
                      />
                      {errors?.assetCategoryId && <span className="invalid">Asset Category is required</span>}
                    </FormGroup>
                  </Col>

                  <Col lg="6">
                    <FormGroup className="form-group">
                      <label className="form-label">
                        <span style={{ color: "indianred" }}></span>Year
                      </label>
                      <Controller
                        control={control}
                        name="year"
                        rules={{ required: true }}
                        render={({ field, ref }) => {
                          return <Input value={field.value} disabled />;
                        }}
                      />
                      {errors?.year && (
                        <span className="invalid" style={{ color: "indianred" }}>
                          Year are required
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup className="form-group">
                      <label className="form-label">
                        <span style={{ color: "indianred" }}></span>Month
                      </label>
                      <Controller
                        control={control}
                        name="months"
                        rules={{ required: true }}
                        render={({ field, ref }) => {
                          const options = MONTH_OPTIONS;
                          const selectedValue = options.filter((e) => field.value && field.value.includes(e.value));
                          const onChange = (o) => {
                            setValue(
                              "months",
                              o.map((item) => item.value)
                            );
                          };
                          return (
                            <RSelect
                              {...field}
                              ref={ref}
                              value={selectedValue}
                              isMulti
                              options={options}
                              onChange={(o) => onChange(o)}
                              isDisabled
                            />
                          );
                        }}
                      />
                      {errors?.months && (
                        <span className="invalid" style={{ color: "indianred" }}>
                          Months are required
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <Label className="form-label" htmlFor="fv-full-name">
                        <span style={{ color: "indianred" }}>&#42;</span>Code
                      </Label>
                      <div className="form-control-wrap">
                        <input
                          {...register("code")}
                          type="text"
                          name="code"
                          className="form-control"
                          disabled={mode === MODE.VIEW}
                        />
                        {errors.title && (
                          <span className="invalid" style={{ color: "red" }}>
                            Code is required
                          </span>
                        )}
                      </div>
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <Label className="form-label" htmlFor="fv-full-name">
                        <span style={{ color: "indianred" }}></span>Frequency
                      </Label>
                      <div className="form-control-wrap">
                        <input
                          {...register("frequency")}
                          type="text"
                          name="frequency"
                          className="form-control"
                          disabled={true}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup className="form-group">
                      <label className="form-label">Description</label>
                      <div className="form-control-wrap">
                        <Controller
                          control={control}
                          name="description"
                          render={({ field, ref }) => {
                            return (
                              <TextEditor
                                isDisabled={mode === MODE.VIEW}
                                field={field}
                                setValue={setValue}
                                fieldName={"description"}
                              />

                            );
                          }}
                        />
                      </div>
                      {errors?.description && (
                        <span className="invalid" style={{ color: "red" }}>
                          {errors?.description.message}
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup className="form-group">
                      <label className="form-label">Comment</label>
                      <div className="form-control-wrap">
                        <Controller
                          control={control}
                          name="comment"
                          render={({ field, ref }) => {
                            return (
                              <TextEditor
                                isDisabled={mode === MODE.VIEW}
                                field={field}
                                setValue={setValue}
                                fieldName={"comment"}
                              />

                            );
                          }}
                        />
                      </div>
                      {errors?.comment && (
                        <span className="invalid" style={{ color: "red" }}>
                          {errors?.comment.message}
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                  {Object.keys(currentTask).length && (
                    <Col lg="12">
                      <FormGroup className="form-group">
                        <div className="form-control-wrap">
                          <Attachments
                            module={TASK}
                            id={currentTaskId}
                            attachments={currentTask.attachment}
                            subModule={mode == MODE?.EDIT ? "" : "view"}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                  )}
                  {/* {mode === MODE.VIEW ? (
                  <Col lg="12">
                    <Comments entityType={TASK} entityId={currentTaskId} />
                  </Col>
                ) : null} */}
                </Row>
                {mode !== MODE.VIEW ? (
                  <Row className="d-flex justify-content-end">
                    <Col className="d-flex justify-content-end my-3">
                      {mode === MODE.EDIT ? (
                        <Button
                          color="danger"
                          size="md"
                          onClick={() => {
                            // reset({ ...currentTask })
                            // setMode(MODE.VIEW)
                            setIsOpen(false);
                          }}
                        >
                          Cancel
                        </Button>
                      ) : null}
                      <Button
                        className="ml-3"
                        color="primary"
                        size="md"
                        onClick={handleSubmit(handleFormSubmit)}
                        isLoading={isLoading}
                      >
                        Save
                      </Button>
                    </Col>
                  </Row>
                ) : null}
              </Form>
            ) : (
              <Skeleton count={15} width={100} />
            )}
            {currentTaskId ? <ActivityLog id={currentTaskId} entityType="TASK" /> : null}
          </PreviewCard>
        </Block>
      </div>
    </>
  );
};
export default PreventativeTaskForm;
