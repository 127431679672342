export const PRIORITY = [
    { value: 'HIGH', label: 'High' },
    { value: 'LOW', label: 'Low' },
    { value: 'NORMAL', label: 'Normal' }
]
export const STATUS = [{ value: "OPEN", label: "Open" },
{ value: "IN_PROGRESS", label: "In Progress" },
{ value: "READY_FOR_APPROVAL", label: "Ready For Approval" },
{ value: "COMPLETED", label: "Completed" },]
export const MODE = {
    VIEW: "VIEW",
    CREATE: "CREATE",
    EDIT: "EDIT"
}
export const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
export const MONTH_OPTIONS = [
    { label: 'January', value: 0 },
    { label: 'February', value: 1 },
    { label: 'March', value: 2 },
    { label: 'April', value: 3 },
    { label: 'May', value: 4 },
    { label: 'June', value: 5 },
    { label: 'July', value: 6 },
    { label: 'August', value: 7 },
    { label: 'September', value: 8 },
    { label: 'October', value: 9 },
    { label: 'November', value: 10 },
    { label: 'December', value: 11 }]