import React, { useContext, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Link, useHistory } from "react-router-dom";
import { Badge, UncontrolledDropdown } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  PaginationComponent,
  Sidebar,
} from "../../../components/Component";
import FilteredByList from "../../../components/FilteredBy";
import Check from "../../../components/check_roles/check";
import { confirmationSweetAlert } from "../../../components/sweetAlert/SweetAlert";
import { errorToast, successToast } from "../../../components/toastify/Toastify";
import { FORM_EDIT_PAGE, FORM_VIEW_PAGE } from "../../../constants/routes";
import ModalViewer from "../../entity_management/ModalViewer";
import { RoleManagementContext } from "../../role_management/RoleManagementProvider";
import { FormManagementContext } from "../FormManagementProvider";
import FormManagementContainer from "../containers/FormManagementFormContainer";
import FormViewContainer from "../containers/FormManagementViewContainer";
import ExportForm from "./ExportForm";
import FormFilter from "./FormFilter";

const FormListCompact = () => {
  // REFS
  const focusSearchRef = React.useRef();

  const history = useHistory();

  const formManagementContext = useContext(FormManagementContext);
  const { formList, pagination } = formManagementContext;

  const {
    loadFormList,
    deleteForm,
    formLoading,
    filterParams,
    handleApplyFilter,
    isFilterApplied,
    sfState,
    filterOptions,
    showFilter,
  } = formManagementContext;

  const { allRoles } = useContext(RoleManagementContext);

  const [sm, updateSm] = useState(false);

  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [form, setForm] = useState("");
  const [view, setView] = useState("");
  const [exportLoading, setExportLoading] = useState(false);
  const [isExpotModalOpen, setIsExportModalOpen] = useState(false);

  let roles = {};

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const toggleExportModal = () => {
    setIsExportModalOpen(!isExpotModalOpen);
  };
  const getExportComponent = () => {
    return <ExportForm onSuccessfulModal={onSuccessfulModal} filterParams={filterParams} />;
  };
  for (let i = 0; i < allRoles?.length; i++) {
    roles[allRoles[i].value] = allRoles[i].label;
  }

  const VIEW = {
    VIEW: "VIEW",
    CREATE: "CREATE",
    EDIT: "EDIT",
  };

  const statusObj = {
    UNPUBLISHED: "danger",
    PUBLISHED: "success",
    DRAFT: "warning",
  };

  useEffect(() => {
    if (focusSearchRef.current) focusSearchRef.current.focus();
  });

  // Changing state value when searching name
  useEffect(() => {}, [onSearchText]);

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  const handleDeleteForm = async (formId) => {
    deleteForm(formId)
      .then((res) => {
        successToast({ description: "Form is deleted successfully" });
      })
      .catch((e) => {
        errorToast({ description: "Error happened while deleting Form" });
      });
  };

  const handleDeleteConfirmation = async (formId) => {
    confirmationSweetAlert({
      id: formId,
      handleConfirmation: handleDeleteForm,
    });
  };

  const onSearchAction = () => {
    handleApplyFilter({ ...filterParams, searchText: onSearchText });
  };

  const currentItems = formList?.items || [];
  const viewForm = (formId) => {
    setForm(formId);
    setView(VIEW.VIEW);
    toggleModal();
  };

  const editForm = (formId) => {
    setForm(formId);
    setView(VIEW.EDIT);
    setIsOpen(true);
  };

  const createForm = () => {
    setView(VIEW.CREATE);
    setIsOpen(!isOpen);
    return <>Edit</>;
  };
  const onSuccessfulModal = () => {
    toggleModal();
    loadFormList();
  };
  const getComponent = () => {
    if (view === VIEW.VIEW) {
      return <FormViewContainer formId={form} setIsOpen={setIsOpen} editForm={editForm} />;
    } else if (view === VIEW.CREATE) {
      return <FormManagementContainer onSuccessfulModal={onSuccessfulModal} />;
    } else if (view === VIEW.EDIT) {
      return <FormManagementContainer formId={form} onSuccessfulModal={onSuccessfulModal} />;
    }
  };
  // function that loads the want to editted data
  const onViewClick = (id, version) => {
    history.push(FORM_VIEW_PAGE.replace(":formId", id)+`?version=${version}`);
  };

  const createExport = () => {
    setIsExportModalOpen(!isExpotModalOpen);
  };

  const handleNameSort = (sortOrder, field) => {
    handleApplyFilter({ ...filterParams, sortingOrder: sortOrder, sortingField: field });
  };


  const selectedRoleOption = filterOptions.formRoles?.find((o) => o.value === filterParams.selectedFormRole) || null;

  return (
    <React.Fragment>
      {
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Dynamic Forms
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>You have a total {formList?.pagination?.totalCount || 0} dynamic forms.</p>
              </BlockDes>
              <div className="d-flex flex-wrap">
                <FilteredByList data={filterParams.selectedFormStatus} title={"Status"} isStr={true} isNotMap={true} />
                {selectedRoleOption ? (
                  <FilteredByList data={selectedRoleOption.label} title={"Role"} isStr={true} isNotMap={true} />
                ) : null}
                <FilteredByList data={filterParams.searchText} title={"Searched by"} isNotMap={true} isStr={true} />
                <FilteredByList data={filterParams.version} title={"Version"} isNotMap={true} isStr={true} />
              </div>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <Check permission={["getForms", "manageForms"]}>
                      <li>
                        <Button
                          disabled={formList?.pagination?.totalCount === 0}
                          className="btn btn-white btn-outline-light"
                          type="export"
                          isLoading={exportLoading}
                          onClick={createExport}
                        >
                          <Icon name="download-cloud"> </Icon>
                          <span>Export</span>
                        </Button>
                      </li>
                    </Check>
                    <Check permission={["manageForms"]}>
                      <li className="nk-block-tools-opt">
                        <Button color="primary" className="btn-icon" onClick={createForm}>
                          <Icon name="plus"></Icon>
                        </Button>
                      </li>
                    </Check>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
      }
      <Block>
        <DataTable className="card-stretch">
          {
            <div className="card-inner position-relative card-tools-toggle ">
              <div className="card-title-group ">
                <div className="card-tools"></div>
                <div className="card-tools mr-n1 ">
                  <ul className="btn-toolbar gx-1">
                    <li>
                      <a
                        href="#search"
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggle();
                        }}
                        className="btn btn-icon search-toggle toggle-search"
                      >
                        <Icon name="search"></Icon>
                      </a>
                    </li>
                    <li className="btn-toolbar-sep"></li>
                    <div>
                      <div tag="a" className="btn btn-icon " onClick={() => sfState.toggle()}>
                        {isFilterApplied ? <div className="dot dot-primary"></div> : null}
                        <Icon name="filter-alt"></Icon>
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
              <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                <div className="card-body">
                  <div className="search-content">
                    <Button
                      className="search-back btn-icon toggle-search active"
                      onClick={() => {
                        setSearchText("");
                        handleApplyFilter({ ...filterParams, searchText: "" });
                        toggle();
                      }}
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                    <input
                      type="text"
                      className="border-transparent form-focus-none form-control"
                      placeholder="Search by model"
                      ref={(el) => {
                        focusSearchRef.current = !onSearch ? el : null;
                      }}
                      value={onSearchText}
                      onChange={(e) => onFilterChange(e)}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          onSearchAction();
                        }
                        if (e.keyCode === 27) {
                          setSearchText("");
                          handleApplyFilter({ ...filterParams, searchText: "" });
                          toggle();
                        }
                      }}
                    />
                    <Button className="search-submit btn-icon">
                      <Icon name="search" onClick={onSearchAction}></Icon>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          }
          {formLoading ? <></> : <DataTableBody className={"table-body"} compact>
            <DataTableHead>
              <DataTableRow>
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "id"
                        ? handleNameSort("DESC", "id")
                        : handleNameSort("ASC", "id");
                    }}
                  >
                    ID
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "id");
                      }}
                      name={`${filterParams.sortingOrder === "ASC" && filterParams.sortingField === "id"
                          ? "caret-up-fill "
                          : "caret-up"
                        } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "id");
                      }}
                      name={`${filterParams.sortingOrder === "DESC" && filterParams.sortingField === "id"
                          ? "caret-down-fill"
                          : "caret-down"
                        }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow>
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "title"
                        ? handleNameSort("DESC", "title")
                        : handleNameSort("ASC", "title");
                    }}
                  >
                    Title
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "title");
                      }}
                      name={`${filterParams.sortingOrder === "ASC" && filterParams.sortingField === "title"
                          ? "caret-up-fill "
                          : "caret-up"
                        } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "title");
                      }}
                      name={`${filterParams.sortingOrder === "DESC" && filterParams.sortingField === "title"
                          ? "caret-down-fill"
                          : "caret-down"
                        }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="md">
                <span className="sub-text">Roles</span>
              </DataTableRow>
              <DataTableRow>
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "version"
                        ? handleNameSort("DESC", "version")
                        : handleNameSort("ASC", "version");
                    }}
                  >
                    Version
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "version");
                      }}
                      name={`${filterParams.sortingOrder === "ASC" && filterParams.sortingField === "version"
                          ? "caret-up-fill "
                          : "caret-up"
                        } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "version");
                      }}
                      name={`${filterParams.sortingOrder === "DESC" && filterParams.sortingField === "version"
                          ? "caret-down-fill"
                          : "caret-down"
                        }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow>
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "status"
                        ? handleNameSort("DESC", "status")
                        : handleNameSort("ASC", "status");
                    }}
                  >
                    Status
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "status");
                      }}
                      name={`${filterParams.sortingOrder === "ASC" && filterParams.sortingField === "status"
                          ? "caret-up-fill "
                          : "caret-up"
                        } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "status");
                      }}
                      name={`${filterParams.sortingOrder === "DESC" && filterParams.sortingField === "status"
                          ? "caret-down-fill"
                          : "caret-down"
                        }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow>{/* <span className="sub-text">Actions</span> */}</DataTableRow>
            </DataTableHead>

            <UncontrolledDropdown isOpen={showFilter} onToggle={sfState.toggle}>
              <Sidebar toggleState={showFilter}>
                <FormFilter />
              </Sidebar>
            </UncontrolledDropdown>

            {/*Head*/}
            {formLoading ? (
              <></>
            ) : (
              currentItems.map((item, key) => {
                return (
                  <DataTableItem key={item.id}>
                    <DataTableRow size="md" className="d-flex justify-content-center">
                      <span className="noWrap">{item.id}</span>
                    </DataTableRow>
                    <DataTableRow>
                      <div
                        className="user-card dropdown-toggle"
                        onClick={() => {
                          viewForm(item.id);
                        }}
                      >
                        <div className="user-name">
                          <span
                            data-toggle="tooltip"
                            title={item.title}
                            style={{
                              width: item.title?.length > 50 ? "350px" : "",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              cursor: "pointer",
                            }}
                            className="tb-lead"
                          >
                            {item?.title || "-"}
                          </span>
                        </div>
                      </div>
                    </DataTableRow>
                    <DataTableRow size="md">
                      <span
                        data-toggle="tooltip"
                        title={item?.name}
                        style={{
                          display:"inline-block",
                          width: (item?.roles
                          ?.map((id, key) => {
                            return roles[id];
                          })
                          .join(", ") || "-").length > 50 ? "350px" : "",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          cursor: "pointer",
                        }}>
                        {item?.roles
                          ?.map((id, key) => {
                            return roles[id];
                          })
                          .join(", ") || "-"}
                      </span>
                    </DataTableRow>
                    <DataTableRow size={"md"}>
                          <span>
                            {item?.version || "-"}
                          </span>
                    </DataTableRow>
                    <DataTableRow size="md">
                      <span className="noWrap">
                        <Badge className="text-capitalize" color={statusObj[item.status]} pill>
                          {item.status || "-"}
                        </Badge>
                      </span>
                    </DataTableRow>

                    <DataTableRow className="d-flex justify-content-center">
                      <Check permission={["manageForms"]}>
                        <Button
                          onClick={() => {
                            editForm(item.id);
                          }}
                        >
                          <Icon name="edit"></Icon>
                          <span className="d-none d-lg-block">Edit</span>
                        </Button>
                      </Check>
                      <Link to={FORM_EDIT_PAGE.replace(":formId", item.id)}>
                        <Button>
                          <Icon name="edit"></Icon>

                          <span className="d-none d-lg-block">Edit Layout</span>
                        </Button>
                      </Link>
                      <Button onClick={() => onViewClick(item.id, item.version)}>
                        {" "}
                        <span className="d-none d-lg-block">View Submissions</span>
                        <Icon name="eye"></Icon>
                      </Button>
                      <Check permission={["manageForms"]}>
                        <Button onClick={() => handleDeleteConfirmation(item.id)}>
                          {" "}
                          <Icon name="trash"></Icon>
                          <span className="d-none d-lg-block">Delete </span>
                        </Button>
                      </Check>
                    </DataTableRow>
                  </DataTableItem>
                );
              })
            )}
          </DataTableBody>}
          {formLoading && <Skeleton count={10} className="w-100" />}
          <div className="card-inner">
            {formList && formList.pagination && formList.pagination.totalCount > 0 ? (
              <PaginationComponent
                itemPerPage={pagination.size}
                totalItems={formList.pagination.totalCount}
                paginate={formManagementContext.updateShowListPage}
                currentPage={formList.pagination.currentPage}
                paginatePage={formManagementContext.updateShowListSize}
              />
            ) : (
              !formLoading && (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )
            )}
          </div>
        </DataTable>
      </Block>
      <ModalViewer title="Form" isOpen={isOpen} size="md" toggleModal={toggleModal} component={getComponent()} />
      <ModalViewer
        title="Form - Select the Export Fields"
        isOpen={isExpotModalOpen}
        size="md"
        toggleModal={toggleExportModal}
        component={getExportComponent()}
      />
    </React.Fragment>
  );
};
export default FormListCompact;
