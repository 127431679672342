/* eslint-disable eqeqeq */
import React from "react";
import { useState } from "react";
import { errorToast } from "../../components/toastify/Toastify";
import useBoolean from "../../hooks/useBoolean";
import { getOptions } from "../maintenance/string/StringRepository";
import { getImportItems, importStringData, updateImport, updateImportItems } from "./ImportRepository";
import IndexCacheService from "../../services/IndexCacheService";
export const ImportContext = React.createContext({
  getImportItemData: async () => {},
  stringData: {},
  setStringData: () => {},
  plants: [],
  failureReason: [],
  loadOptions: () => {},
  stringLoading: false,
  importStrings: async () => {},
  isStringImport: false,
  isUpdateImportLoading: false,
  updateImportData: async () => {},
  updateImportItemData: async () => {},
});

export const ImportProvider = (props) => {
  const [plants, setPlants] = React.useState([]);
  const [failureReason, setFailureReason] = React.useState([]);
  const [stringData, setStringData] = useState([]);
  const [stringLoading, slState] = useBoolean(false);
  const [isStringImport, isiState] = useBoolean(false);
  const [isUpdateImportLoading, iuilState] = useBoolean(false);
  const getImportItemData = async (importId, plantId) => {
    try {
      slState.on();
      const response = await getImportItems(importId);
      if (response.message) {
      } else {
      }
      const temp = response.map((r) => {
        return {
          plantId,
          status: "OPEN",
          statusOpen: false,
          failureReasonIdOpen: false,
          ...r.data,
          importItemId: r.id,
          addedAt: r.data.addedAt ? r.data.addedAt : null,
          investigatedAt: r.data.investigatedAt ? new Date(r.data.investigatedAt) : null,
          repairedAt: r.data.repairedAt ? new Date(r.data.repairedAt) : null,
        };
      });
      setStringData(temp);
      // with import id go to string import url
    } catch (e) {
      // throw e;
      errorToast({ description: e.message });
    } finally {
      slState.off();
    }
  };

  const loadOptions = async () => {
    try {
      const response = await getOptions();
      setPlants(response.plants);
      setFailureReason(response.failureReason);
    } catch (e) {
      // CAPTURE EXCEPTION
      throw e;
    }
  };

  const importStrings = async (importId) => {
    try {
      isiState.on();
      await importStringData(stringData, importId);
      await IndexCacheService.clearCache()
    } catch (e) {
      errorToast({ description: e.message });
    } finally {
      isiState.off();
    }
  };

  const updateImportData = async (importId, data) => {
    try {
      iuilState.on();
      await updateImport(importId, data);
    } catch (e) {
      errorToast({ description: e.message });
    } finally {
      iuilState.off();
    }
  };

  const updateImportItemData = async (data) => {
    try {
      const res = await updateImportItems(data);
      return res;
    } catch (e) {
      errorToast({ description: e.message });
    }
  };

  const mContext = {
    getImportItemData,
    stringData,
    setStringData,
    plants,
    failureReason,
    loadOptions,
    stringLoading,
    importStrings,
    isStringImport,
    isUpdateImportLoading,
    updateImportData,
    updateImportItemData,
  };

  return <ImportContext.Provider value={mContext}>{props.children}</ImportContext.Provider>;
};

export const withImportProvider = (Container, containerProps) => (props) =>
  (
    <ImportProvider>
      <Container {...containerProps} {...props} />
    </ImportProvider>
  );
