import React, { useContext, useEffect, useState } from "react";
import { FormGroup, UncontrolledDropdown } from "reactstrap";
import { Button, Col, Icon, Row, RSelect, Sidebar } from "../../../components/Component";
import { FormManagementContext } from "../FormManagementProvider";
import DatePicker from "react-datepicker";

const FormSubmissionFilter = () => {
  const formManagementContext = useContext(FormManagementContext);
  const { filterOptions, pagination } = formManagementContext;
  const {
    filterParams,
    handleApplyFilter,
    sfState,
    showFilter,
    loadSubmittedForm,
    submittedForms,
    loadFormFilterOption,
    formFilterOptions,
  } = formManagementContext;

  const [selectedFilter, setSelectedFilter] = React.useState(filterParams);
  const itemPerPage = pagination.size;

  const {toDate, fromDate} = selectedFilter;
  const [tablesm, updateTableSm] = useState(false);

  useEffect(() => {
    setSelectedFilter(filterParams);
  }, [filterParams]);
  useEffect(() => {
    loadFormFilterOption();
  }, []);
  //   useEffect(() => {
  //     loadSubmittedForm();
  //   }, []);

  const handleReset = () => {
    formManagementContext.resetFormListFilter();
  };
  
  const handleApply = () => {
    handleApplyFilter(selectedFilter);
    updateTableSm(false);
    sfState.off();
  };

  const handleFilterSelection = (params) => {
    setSelectedFilter({
      ...selectedFilter,
      ...params,
    });
  };

  const handleSubmissionFilterValues = (field, selectedOption) => {
    const selectedFields = {
      ...selectedFilter,
      [field]: selectedOption || "",
    };
    handleFilterSelection(selectedFields);
  };

  const isBothFromandTwoDateSelected = Boolean(fromDate) ^ Boolean(toDate)

  const selectedCreatedByOption =
    formFilterOptions.CreatedBy?.find((o) => o.value === selectedFilter.selectedCreatedBy) || null;

  return (
    <div className="vh-100  ">
      <div className="dropdown-head">
        <span className="sub-title dropdown-title fs-16px">Filter Options</span>
        <div onClick={sfState.off}>
          <Icon className={"fs-18px text-dark"} style={{ cursor: "pointer" }} name={"cross"}></Icon>
        </div>
      </div>
      <UncontrolledDropdown isOpen={showFilter} onToggle={sfState.toggle}>
        <div className="dropdown-body dropdown-body-rg">
          <Row className="gx-6 gy-3">
            <Col size="12">
              <FormGroup>
                <label className="overline-title overline-title-alt">Submitted By</label>
                <RSelect
                  options={formFilterOptions.CreatedBy}
                  placeholder="Submitted By"
                  onChange={(data) =>{
                     handleSubmissionFilterValues("selectedCreatedBy", data?.value)
                  }}
                  value={selectedCreatedByOption}
                />
              </FormGroup>
            </Col>
                <Col size={12}>
                <label className="overline-titlModele overline-title-alt">Submitted At</label>
                </Col>
                <Col size={"6"}>
                  <FormGroup>
                    <label className="overline-title overline-title-alt">From</label>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      className="form-control"
                      selected={selectedFilter.fromDate ? new Date(selectedFilter.fromDate) : null}
                      onChange={(date) => {
                          handleSubmissionFilterValues("fromDate", date)
                        }}
                    />
                  </FormGroup>
                </Col>  
                <Col size={"6"}>
                  <FormGroup>
                    <label className="overline-title overline-title-alt">To</label>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      className="form-control"
                      selected={selectedFilter.toDate ? new Date(selectedFilter.toDate) : null}
                      onChange={(date) => {
                        handleSubmissionFilterValues("toDate", date);
                      }}
                    />
                  </FormGroup>
                </Col>
            <Col className="d-flex justify-content-between" size="12">
              <Button
                outline={true}
                onClick={(ev) => {
                  ev.preventDefault();
                  handleReset();
                }}
                color="secondary"
              >
                {" "}
                Reset Filter
              </Button>
              <Button disabled={isBothFromandTwoDateSelected} onClick={handleApply} color="secondary">
                Apply
              </Button>
            </Col>
          </Row>
        </div>
      </UncontrolledDropdown>
    </div>
  );
};

export default FormSubmissionFilter;
