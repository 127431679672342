/* eslint-disable react-hooks/exhaustive-deps */
import { format } from "date-fns";
import React, { useContext, useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useLocation } from "react-router-dom";
import { Badge, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  DataTable,
  Icon,
  PaginationComponent,
  Sidebar,
  UserAvatar
} from "../../../components/Component";
import { confirmationSweetAlert } from "../../../components/sweetAlert/SweetAlert";
import { errorToast, successToast } from "../../../components/toastify/Toastify";
import { MONTH, STATUS_OPTION, statusColorTheme } from "../../../constants/constants";
import { PREVENTATIVE_GENERATOR_PAGE } from "../../../constants/routes";
import ModalViewer from "../../maintenance/ModalViewer";
import { MODE, MONTH_OPTIONS } from "../../maintenance/preventative/constants";
import { PreventativeTaskContext } from "../../maintenance/preventative/preventativeTasks/PreventativeTaskProvider";
import { deletePreventativeTask } from "../../maintenance/preventative/preventativeTasks/PreventativeTaskRepository";
import ExportForm, { TASK_FIELDS } from "../../maintenance/preventative/preventativeTasks/components/ExportForm";
import PreventativeListFilter from "../../maintenance/preventative/preventativeTasks/components/PreventativeListFilter";
import PreventativeTaskForm from "../../maintenance/preventative/preventativeTasks/components/PreventativeTaskForm";
import Check from "../../../components/check_roles/check";
import { createPortal } from "react-dom";
import { createColumnHelper } from "@tanstack/react-table";
import TanstackTable from "../../../components/tableV1/Table";
import SortableColumns from "../../../components/tableV1/SortableColumns";
import { taskCategory } from "../../../constants/constants";
import useBoolean from "../../../hooks/useBoolean";
import { MaintenanceContext } from "../../../providers/Maintenance.provider";
import { findUpper, openImageInNewTab } from "../../../utils/Utils";
import { updateViewSettings } from "../../../repositories/ViewRepository";
import ToolTip from "../../../components/ToolTip";
import AttachmentList from "../../../components/FileAttachment";

const columnHelper = createColumnHelper();

const PlantPreventiveList = ({ plantId, plantStatus, isArchived }) => {
  const location = useLocation().search;

  const preventativeContext = useContext(PreventativeTaskContext);
  const {
    pagination,
    filterParams,
    preventativeTasks,
    plants,
    engineers,
    preventativeTaskLoading,
    handleApplyFilter,
    loadPreventativeTasks,
    showFilter,
    sfState,
    isFilterApplied,
    isSubFilterApplied,
    archiveTask,
    unarchiveTask,
    tasksColumnSettings,
    setTasksColumnSettings, getTableSettings,
    deleteTask,
  } = preventativeContext;
  const { userList } = useContext(MaintenanceContext)
  const [sm, updateSm] = useState(false);
  const [mode, setMode] = useState("");
  const [onSearch, setOnSearch] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [currentTaskId, setCurrentTaskId] = useState("");
  const [exportLoading, setExportLoading] = useState(false);
  const [isExpotModalOpen, setIsExportModalOpen] = useState(false);
  const [isColumnArrangeModalOpen, icamState] = useBoolean(false);
  const [selectedAttachment, setSelectedAttachment] = useState({});
  const [isAttachmentModalOpen, iamoState] = useBoolean(false);

  const renderTableValue = (tableValue) => {
    if (!tableValue) {
      return <div>-</div>;
    }
    return <div data-toggle="tooltip" title={tableValue}>{tableValue} </div>;
  };

    // handle individual page change
    const handlePageChange = (page) => {
      preventativeContext.handleApplyFilter({ ...filterParams, page });
      setTasksColumnSettings({ ...tasksColumnSettings,
        filters: {
          ...tasksColumnSettings.filters,
          page
        },
      });
    }
  
    //handle page size
    const handlePageSizeChange = (size) => {
      preventativeContext.handleApplyFilter({ ...filterParams, page: 1, size });
      setTasksColumnSettings({ ...tasksColumnSettings,
        filters: {
          ...tasksColumnSettings.filters,
          page: 1,
          size
        },
      });            
    }

  function tableColumn(columnSetting) {
    switch (columnSetting.field_key) {
      case "title":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => "Ticket Name",
          cell: (info) => {
            return (
              <div style={{ display: "flex", gap: "10px", cursor: "pointer", }}
                onClick={() => {
                  viewTask(info.row.original.id);

                }}>
                <div className="user-card">
                  <UserAvatar theme={"primary"} className="xs" text={findUpper(info.row.original?.title)} />
                  <div className="user-name"></div>
                  <span
                    data-toggle="tooltip"
                    title={info.row.original?.title}
                    style={{
                      width: info.row.original?.title?.length > 50 ? "350px" : "",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      cursor: "pointer",
                    }}
                    className="tb-lead"
                  >
                    {info.row.original?.title || "-"}
                  </span>
                </div>
                {/* <p style={{ fontWeight: "bold", color: "#364a63" }}>{renderTableValue(info.getValue())}</p> */}
              </div>
            );
          },
        });
      case "status":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => renderTableValue(
            <Badge className="text-capitalize" style={statusColorTheme[info.row.original?.status]} pill>
              <span>{STATUS_OPTION[info.row.original?.status] || "-"}</span>
            </Badge>
          ),
        });
      case "identifier":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => renderTableValue(info.getValue()),
        });
      case "startedAt":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => {
            if (info.row.original?.startedAt) {
              return `${format(new Date(info.row.original?.startedAt || null), "dd/MM/yyyy")}`
            }
            else {
              return "-"
            }
          }
        });

      case "resolvedAt":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => {
            if (info.row.original?.resolvedAt) {
              return `${format(new Date(info.row.original?.resolvedAt || null), "dd/MM/yyyy")}`
            }
            else {
              return "-"
            }
          }
        });
      case "isArchived":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => <p>{info.getValue() ? "Archived" : "UnArchived"}</p>,
        });
      case "priority":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => renderTableValue(info.getValue()),
        });
      case "code":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => renderTableValue(info.getValue()),
        });
      case "frequency":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => renderTableValue(info.getValue()),
        });
      case "year":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => renderTableValue(info.getValue()),
        });
      case "months":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => {
            if (!info.row.original?.months?.length) {
              return <p>-</p>;
            }
            const monthList = MONTH_OPTIONS.filter((item) => info.row.original?.months.includes(item.value));
            return (
              <div style={{ display: "flex" }}>
                {monthList.map((m, i) => {
                  return (
                    <div key={`month-${i}`}>
                      {m.label}
                      {i !== monthList.length - 1 && <span>,&nbsp;</span>}
                    </div>
                  );
                })}
              </div>
            );
          },
        });
      case "description":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => <ToolTip id={`id-${info.row.original?.id}-description`} value={info.getValue()}/>,
        });
      case "comment":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => <ToolTip id={`id-${info.row.original?.id}-comment`} value={info.getValue()}/>,
        });

      case "category":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => {
            if (info.row.original?.category) {
              return `${taskCategory(info.row.original.category)}`
            }
            else {
              return "-"
            }
          }

        });
      case "assetCategoryId":
        return columnHelper.accessor("assetCategory", {
          id: "assetCategoryId",
          header: () => "Asset Category",
          cell: (info) => renderTableValue(info.row.original?.assetCategory?.name),
        });
      case "createdById":
        return columnHelper.accessor("createdBy", {
          id: "createdById",
          header: () => columnSetting.field_name ?? "",
          cell: (info) => {
            if (info.row.original?.createdBy) {
              return (
                <p>
                  {info.row.original?.createdBy?.firstName}
                  {info.row.original?.createdBy?.lastName}
                </p>
              );
            }
            else {
              return "-";
            }
          },
        });
      case "parentId":
        return columnHelper.accessor("parentId", {
          id: "parentId",
          header: () => columnSetting.field_name ?? "",
          cell: (info) => {
            if (info.row.original?.createdBy) {
              return (
                <p>
                  {info.row.original?.createdBy?.firstName}
                  {info.row.original?.createdBy?.lastName}
                </p>
              );
            }
            else {
              return "-";
            }

          },
        });
      case "fieldEngineerIds":
        return columnHelper.accessor("fieldEngineerIds", {
          id: columnSetting.field_key,
          enableSorting: false,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => {
            if (!info.row.original?.fieldEngineerIds?.length) {
              return <p>-</p>;
            }
            const user = userList.filter((item) => info.row.original?.fieldEngineerIds.includes(item.value));
            return (
              <div style={{ display: "flex" }}>
                {user.map((u, i) => {
                  return (
                    <div key={`field-engineer-${i}`}>
                      {u.label}
                      {i !== user.length - 1 && <span>,&nbsp;</span>}
                    </div>
                  );
                })}
              </div>
            );
          },
        });
      case "assignedToIds":
        return columnHelper.accessor("assignedToIds", {
          id: columnSetting.field_key,
          enableResizing: true,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => {
            if (!info.row.original?.assignedToIds?.length) {
              return <p>-</p>;
            }
            return (
              <div style={{ display: "flex" }}>
                {info.row.original?.assignedToIds
                  ?.map((id, key) => {
                    return getEngineerName(id);
                  })
                  .join(", ") || "-"}

              </div>
            )
          },
        });
      case "attachment":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          enableSorting: false,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => <AttachmentList
            attachments={info.row.original?.attachment}
            taskName={info.row.original?.title}
            openImageInNewTab={openImageInNewTab}
            setSelectedAttachment={setSelectedAttachment}
            iamoState={iamoState}
          />
        });
      default:
        return columnHelper.accessor(columnSetting.field_key, {
          id: "id",
          enableSorting: false,
          header: () => columnSetting.field_key ?? "",
          cell: (info) => {
            return <p>unknown column</p>;
          },
        });
    }
  }

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const STATUSES = {
    OPEN: "Open",
    IN_PROGRESS: "In Progress",
    READY_FOR_APPROVAL: "Ready For Approval",
    COMPLETED: "Completed",
  };

  const toggleExportModal = () => {
    setIsExportModalOpen(!isExpotModalOpen);
  };

  const toggleSearch = () => setOnSearch(!onSearch);

  const focusSearchRef = useRef();

  useEffect(() => {
    if (focusSearchRef.current) focusSearchRef.current.focus();
  });
  // useEffect(() => {
  //   if (plantId && !status) {
  //     handleApplyFilter({ ...filterParams, selectedPlant: [parseInt(plantId)] });
  //   }
  //   if (status && plantId) {
  //     handleApplyFilter({
  //       ...filterParams,
  //       selectedTaskStatus: [status],
  //       selectedPlant: [parseInt(plantId)],
  //       dashboard,
  //     });
  //   }
  // }, [status, plantId]);

  // const fetchPreventativeTasksData = async () => {
  //   const settings = await getTableSettings({
  //     entityType: "PREVENTATIVE_PLANT",
  //   });
  //   if (settings && plantId) {
  //     await loadPreventativeTasks(settings, plantId, status, dashboard);
  //     // await loadFilterOptions()
  //   };
  // }
  // useEffect(() => {
  //   if (!isOpen) {
  //     fetchPreventativeTasksData();
  //   }
  // }, [isOpen]);
  // useEffect(() => {
  //   // if (plantId) {
  //   //   fetchPreventativeTasksData()
  //   // }
  //   if (plantId && !status) {
  //     handleApplyFilter({ ...filterParams, selectedPlant: [parseInt(plantId)] });
  //   }
  //   if (status && plantId) {
  //     handleApplyFilter({
  //       ...filterParams,
  //       // selectedTaskStatus: [status],
  //       selectedPlant: [parseInt(plantId)],
  //       dashboard,
  //     });
  //   }
  // }, [plantId, status]);

  const onSuccessfulModal = () => {
    handleApplyFilter({
      ...tasksColumnSettings,
      filters: { ...tasksColumnSettings.filters },
    });
  };
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  const onSearchAction = () => {
    handleApplyFilter({ ...filterParams, taskText: searchText });
  };

  const currentItems = preventativeTasks?.items || [];

  const viewTask = (id) => {
    setMode(MODE.VIEW);
    setIsOpen(true);
    setCurrentTaskId(id);
  };
  const editTask = (id) => {
    setMode(MODE.EDIT);
    setIsOpen(true);
    setCurrentTaskId(id);
  };
  const deleteFunc = async (taskId) => {
    try {
      await deleteTask(taskId, plantId);
      setIsOpen(false);
      successToast({ description: "Successfully Deleted" });
    } catch (err) {
      errorToast({ description: "Error happened while deleting Preventative Task" });
    }
  };
  const getEngineerName = (plantId) => {
    const engineer = engineers.find((p) => p.value === plantId);
    return engineer?.label || "";
  };
  const handleDeleteConfirmation = (taskId) => {
    confirmationSweetAlert({
      id: taskId,
      handleConfirmation: deleteFunc,
    });
  };
  const handleArchive = async (taskId) => {
    archiveTask(taskId)
      .then((res) => {
        successToast({ description: " Preventative Task Archived successfully" });
      })
      .catch((e) => {
        errorToast({ description: "Error happened while archive Preventative Task" });
      });
  };
  const handleUnArchive = async (taskId) => {
    unarchiveTask(taskId, plantId)
      .then((res) => {
        successToast({ description: "Preventative Task UnArchived successfully" });
      })
      .catch((e) => {
        errorToast({ description: "Error happened while unarchive Preventative Task" });
      });
  };

  async function handleUpdateViewSettings(payload) {
    return await updateViewSettings(payload);
  }

  const handleNameSort = (sortOrder, field) => {
    handleApplyFilter({ ...filterParams, sortingOrder: sortOrder, sortingField: field });
    const updatedSettings = {
      ...tasksColumnSettings,
      filters: { ...tasksColumnSettings.filters, sortingField: field, sortingOrder: sortOrder },
    };
    setTasksColumnSettings(updatedSettings);
  };

  const getComponent = () => {
    return (
      <PreventativeTaskForm
        mode={mode}
        setMode={setMode}
        currentTaskId={currentTaskId}
        handleDeleteConfirmation={handleDeleteConfirmation}
        setIsOpen={setIsOpen}
        plantId={plantId}
      />
    );
  };
  const getExportComponent = () => {
    return <ExportForm plantId={plantId} onSuccessfulModal={onSuccessfulModal} filterParams={filterParams} />;
  };
  const createExport = () => {
    setIsExportModalOpen(!isExpotModalOpen);
  };
  const columns = React.useMemo(() => {
    if (tasksColumnSettings?.fields?.length) {
      return tasksColumnSettings.fields
        .filter((cs) => cs.isChecked)
        .map((columnSetting) => {
          return tableColumn(columnSetting);
        });
    }
    return [];
  }, [preventativeTasks.items]);

  const handleClearSearch = async () => {
    if(!searchText && !filterParams.searchText) {
      toggleSearch();
    } else {
      setSearchText("");
      handleApplyFilter({ ...filterParams, taskText: "" });
      toggleSearch();
    }
  }

  const renderAction = React.useMemo(() => {
    return [
      columnHelper.accessor("id", {
        header: "",
        size: 40,
        enableSorting: false,
        enableResizing: false,
        cell: (info) => {
          const item = info?.row.original;
          return (
            <ul className="nk-tb-actions gx-1">
              <UncontrolledDropdown>
                <DropdownToggle id="menu-btn-preventative" tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                  <Icon name="more-h"></Icon>
                </DropdownToggle>
                {createPortal(
                  <DropdownMenu right>
                    <ul className="link-list-opt no-bdr">
                      <React.Fragment>
                        <Check permission={["manageTickets"]}>

                          <>
                            <li /* className="nk-tb-action-hidden" */ onClick={() => editTask(item.id)}>
                              <DropdownItem
                                id="edit-preventative-btn"
                                tag="a"
                                href="#edit"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                }}
                              >
                                <Icon name="edit-alt-fill"></Icon>
                                <span>Edit Task</span>
                              </DropdownItem>
                            </li>
                            <li className="divider"></li>
                          </>

                          {item.isArchived ? (
                            <React.Fragment>
                              <li onClick={() => handleUnArchive(item.id)}>
                                <DropdownItem
                                  id="unarchive-preventative"
                                  tag="a"
                                  href="#unarchive"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                  }}
                                >
                                  <Icon name="unarchive"></Icon>
                                  <span>UnArchive Task</span>
                                </DropdownItem>
                              </li>
                              <li className="divider"></li>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <li onClick={() => handleArchive(item.id)}>
                                <DropdownItem
                                  id="archive-preventative"
                                  tag="a"
                                  href="#archive"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                  }}
                                >
                                  <Icon name="archive"></Icon>
                                  <span>Archive Task</span>
                                </DropdownItem>
                              </li>
                            </React.Fragment>
                          )}
                        </Check>
                        <Check permission={["manageTickets"]}>
                          {item.status !== "SUSPENDED" && (
                            <>
                              <li className="divider"></li>
                              <li onClick={() => handleDeleteConfirmation(item.id)}>
                                <DropdownItem
                                  id="delete-preventative"
                                  tag="a"
                                  href="#delete"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                  }}
                                >
                                  <Icon name="trash"></Icon>
                                  <span>Delete Task</span>
                                </DropdownItem>
                              </li>
                            </>
                          )}
                        </Check>
                      </React.Fragment>
                    </ul>
                  </DropdownMenu>,
                  document.body
                )}
              </UncontrolledDropdown>

            </ul>
          );
        },
      }),
    ];
  }, [preventativeTasks.items]);

  const renderTable = React.useMemo(() => {
    return (

      <TanstackTable
        dataSource={preventativeTasks.items ?? []}
        _column={[...columns, ...renderAction]}
        columnSettings={tasksColumnSettings}
        onColumWidthChanged={(resizedColumn) => {
          const _tasksColumnSettings = [...tasksColumnSettings.fields].map((ts) => {
            if (ts.field_key === resizedColumn.field_key) {
              return {
                ...ts,
                width: resizedColumn.width,
              };
            } else {
              return ts;
            }
          });
          handleUpdateViewSettings({
            entityType: "PREVENTATIVE_PLANT",
            fields: _tasksColumnSettings,
          });
          setTasksColumnSettings((prev) => ({ ...prev, fields: _tasksColumnSettings }));
        }}
        handleSorting={(sortingParams) => {
          handleNameSort(sortingParams.sortingOrder, sortingParams.sortingField, sortingParams.table);
        }}

      />
    )
  }, [columns, preventativeTasks.items, tasksColumnSettings]);

  return (
    <>
      <Block className={"bg-white p-4"}>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page className="ml-md-4">
                Preventative Task Lists
              </BlockTitle>
              <BlockDes className="text-soft ml-md-4">
                <p>You have a total of {pagination?.totalCount || 0} preventative tasks.</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3 d-inline-block">
                    <Check permission={["getTickets", "manageTickets"]}>
                      <li>
                        <Button
                          disabled={pagination?.totalCount === 0}
                          id="export-preventative"
                          className="btn btn-white btn-outline-light"
                          type="export"
                          isLoading={exportLoading}
                          onClick={createExport}
                        >
                          <Icon name="download-cloud"> </Icon>
                          <span>Export</span>
                        </Button>
                      </li>
                    </Check>
                    {plantStatus === "ACTIVE" && isArchived === false && (
                      <Check permission={["manageTickets"]}>
                        <li>
                          <a
                            href={`${PREVENTATIVE_GENERATOR_PAGE}?plant=${plantId}`}
                            // onClick={(ev) => {
                            //     ev.preventDefault();
                            // }}
                            className="btn btn-primary "
                          >
                            <Icon name="plus"></Icon>
                            <span>Generate Preventative Task</span>
                          </a>
                        </li>
                      </Check>
                    )}
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <DataTable className="card-stretch">

            <div className="card-inner position-relative card-tools-toggle ">
              <div className="card-title-group ">
                <div className="card-tools"></div>
                <div className="card-tools mr-n1 ">
                  <ul className="btn-toolbar gx-1">
                    <li>
                      <a
                        id="search-preventative"
                        href="#search"
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggleSearch();
                        }}
                        className="btn btn-icon search-toggle toggle-search"
                      >
                        <Icon name="search"></Icon>
                      </a>
                    </li>
                    <li className="btn-toolbar-sep"></li>

                    <Sidebar toggleState={showFilter}>
                      <div>
                        <PreventativeListFilter isPlantName={false} plants={plants} engineers={engineers} subList={true} selectedPlant={plantId} />
                      </div>
                    </Sidebar>
                    <div id="filter-preven" tag="a" className="btn btn-icon " onClick={() => sfState.toggle()}>
                      {isFilterApplied ? <div className="dot dot-primary"></div> : null}
                      <Icon name="filter-alt"></Icon>
                    </div>
                    {preventativeTasks?.pagination?.totalCount > 0 &&
                      <div id="task-filter" tag="a" className="btn btn-icon " onClick={() => icamState.on()}>
                        <Icon name="view-list-fill"></Icon>
                      </div>
                    }
                  </ul>
                </div>
              </div>
              <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                <div className="card-body">
                  <div className="search-content">
                    <Button
                      className="search-back btn-icon toggle-search active"
                      onClick={handleClearSearch}
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                    <input
                      type="text"
                      className="border-transparent form-focus-none form-control"
                      placeholder="Search by Ticket Name,status .enter to search"
                      ref={(el) => {
                        focusSearchRef.current = !onSearch ? el : null;
                      }}
                      value={searchText}
                      onChange={(e) => onFilterChange(e)}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          onSearchAction();
                        }
                        if (e.keyCode === 27) {
                          handleClearSearch()
                        }
                      }}
                    />
                    <Button className="search-submit btn-icon">
                      <Icon name="search" onClick={onSearchAction}></Icon>
                    </Button>
                  </div>
                </div>
              </div>
              <ModalViewer
                title={`${selectedAttachment.taskName}'s Attachments`}
                isOpen={isAttachmentModalOpen}
                size="md"
                toggleModal={iamoState.toggle}
                component={
                  Object.keys(selectedAttachment).length ? (
                    <div>
                      {selectedAttachment?.attachments.map((attachment) => {
                        return (
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <em class="icon ni ni-dot"></em>
                            <p
                              style={{ cursor: "pointer" }}
                              onClick={() => openImageInNewTab(attachment?.files.key)}
                            >
                              <div style={{ color: "#0000EE", cursor: "pointer" }} href="">{attachment?.files?.name}</div>
                              </p>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <></>
                  )
                }
              />
              <ModalViewer
                title="Preventative Task Columns"
                isOpen={isColumnArrangeModalOpen}
                size="md"
                toggleModal={icamState.toggle}
                component={
                  <SortableColumns
                    entityType={"PREVENTATIVE_MAINTENANCE"}
                    fields={TASK_FIELDS}
                    updateViewSettings={async (modifiedSettings) => {
                      const updatedSettings = await handleUpdateViewSettings({
                        ...tasksColumnSettings,
                        fields: modifiedSettings,
                      });
                      setTasksColumnSettings((prev) => ({ ...prev, fields: updatedSettings.fields }));
                    }}
                    closeModal={icamState.off}
                    visibleColumns={tasksColumnSettings.fields}
                  />
                }
              />
            </div>
            {preventativeTaskLoading || preventativeContext.isLoadingFilters ? <Skeleton count={10} className="w-100" /> :
              (preventativeTasks?.pagination?.totalCount > 0) && renderTable}

            <div className="card-inner">
              {preventativeTasks.pagination && preventativeTasks.pagination.totalCount > 0 ? (
                <PaginationComponent
                  itemPerPage={filterParams?.size}
                  totalItems={pagination.totalCount}
                  paginate={(page) => {
                    handlePageChange(page)
                  }}
                  paginatePage={(size) => {
                    handlePageSizeChange(size)
                  }}
                  currentPage={pagination.currentPage}
                />
              ) : (
                !preventativeTaskLoading && (
                  <div className="text-center">
                    <span className="text-silent">No data found</span>
                  </div>
                )
              )}
            </div>
          </DataTable>
        </Block>
        <ModalViewer title="Preventative Task" isOpen={isOpen} toggleModal={toggleModal} component={getComponent()} />
      </Block>
      <ModalViewer
        title="Preventative Maintenance - Select the Export Fields"
        isOpen={isExpotModalOpen}
        size="md"
        toggleModal={toggleExportModal}
        Month
        component={getExportComponent()}
      />
    </>
  );
};
export default PlantPreventiveList;
