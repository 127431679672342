import React from "react";
import useBoolean from "../../../hooks/useBoolean";
import {
  deleteTaskAPI,
  getRemedialList,
  getTaskAPI,
  updateTaskAPI,
  getAllRemedialtasks,
  archiveTaskAPI,
  unarchiveTaskAPI,
} from "./RemedialRepository";
import { getTableViewSettings } from "../../../repositories/ViewRepository";
import IndexCacheService from "../../../services/IndexCacheService";

const initialPaginationState = {
  currentPage: 1,
  pageSize: 10,
  totalCount: 0,
  totalPages: 1,
};

export const RemedialContext = React.createContext({
  pagination: { ...initialPaginationState },
  loadRemedialTasks: async (filterParams = {}) => { },
});

const initialFilterParams = {
  selectedTaskStatus: [],
  selectedAssignedEngineer: [],
  selectedPlant: [],
  isArchiveSelected: false,
  searchText: "",
  startDate: "",
  resolvedDate: "",
  sortingOrder: "",
  sortingField: "",
  table: "",
  page: 1,
  size: 10,
};

export const RemedialProvider = (props) => {
  const [remedialList, setRemedialList] = React.useState([]);
  const [pagination, setPagination] = React.useState(Object.assign({}, initialPaginationState));
  const [filterParams, setFilterParams] = React.useState(Object.assign({}, initialFilterParams));
  const [currentViewTask, setCurrentViewTask] = React.useState();
  const [remedialTasks, setRemedialTasks] = React.useState([]);
  const [tasksColumnSettings, setTasksColumnSettings] = React.useState([]);

  //Loaders
  const [remedialLoading, remedialState] = useBoolean(false);
  const [loadingTasks, tlState] = useBoolean(false);
  const [showFilter, sfState] = useBoolean(false);
  const [isLoadingFilters, ilfState] = useBoolean(false);

  const getRemedialListFilterParams = () => {
    const queryParams = {
      page: filterParams.page,
      size: filterParams.size,
    };
    const {
      selectedTaskStatus,
      isArchiveSelected,
      searchText,
      selectedAssignedEngineer,
      resolvedDate,
      startDate,
      selectedPlant,
      sortingOrder,
      sortingField,
      table
    } = filterParams;
    if (selectedTaskStatus.length) queryParams["taskStatus"] = selectedTaskStatus;
    if (selectedPlant.length) queryParams["taskPlant"] = selectedPlant;
    if (selectedAssignedEngineer.length) queryParams["taskAssignedEngineer"] = selectedAssignedEngineer;
    if (searchText) queryParams["taskText"] = searchText;
    if (isArchiveSelected != null) queryParams["taskArchived"] = isArchiveSelected;
    if (startDate) queryParams["taskStartDate"] = startDate;
    if (resolvedDate) queryParams["taskResolvedDate"] = resolvedDate;
    if (sortingField) queryParams["sortingField"] = sortingField;
    if (sortingOrder) queryParams["sortingOrder"] = sortingOrder;
    if (table) queryParams["table"] = table;
    return queryParams;
  };

  const getTableSettings = async (entityType,useSettingsFilter = true) => {
    try {
      ilfState.on()
      const settings = await getTableViewSettings(entityType);
      setTasksColumnSettings(settings)
      const constructFilterParams = {
        selectedTaskStatus: settings.filters.taskStatus ?? [],
        isArchiveSelected: settings.filters.taskArchived ?? false,
        sortingOrder: settings.filters.sortingOrder ?? "",
        sortingField: settings.filters.sortingField ?? "",
        selectedPlant: settings.filters.taskPlant ?? [],
        selectedAssignedEngineer: settings.filters.taskAssignedEngineer ?? [],
        startDate: settings.filters.taskStartDate ?? "",
        resolvedDate: settings.filters.taskResolvedDate ?? "",
        page: settings.filters.page ?? 1,
        size: settings.filters.size ?? 10
      };
      if (useSettingsFilter) {
        handleApplyFilter(constructFilterParams)
      }
      return settings;
    } catch (error) {
      throw error;
    } finally {
      ilfState.off()
    }
  };


  //LOAD REMEDIAL LIST
  const loadRemedialList = async (settings, plantId, status) => {
    remedialState.on();
    if (status) {
      handleApplyFilter({ ...filterParams, selectedTaskStatus: [status] });
    }
    try {
      const filterParams = getRemedialListFilterParams();
      if (status) {
        filterParams["taskStatus"]  = status;
      } 
      if (plantId) {
        filterParams["taskPlant"] = plantId;
      }
      const attributes = settings?.fields?.filter((f) => f.isChecked).map((field) => field.field_key);
      const queryParams = Object.assign(filterParams, {
        ...settings.filters,
      });

      const response = await getRemedialList(queryParams, { attributes });
      setRemedialList(response.remedialList);
    } catch (e) {
      // CAPTURE EXCEPTION
      throw e;
    } finally {
      remedialState.off();
    }
  };

  const loadRemedialTasks = async(nextFilterParams = {})=>{
    const settings = {...tasksColumnSettings}
    remedialState.on()
    setRemedialList([])
    const {plantId} = nextFilterParams
    delete nextFilterParams["plantId"]
    try{
      const filterParams = {...getRemedialListFilterParams(), ...nextFilterParams}
      if(plantId){
        filterParams["taskPlant"] = plantId
      }
      const attributes = settings?.fields?.filter((f)=>f.isChecked).map((field)=>field.field_key)
      let queryParams = {
        ...filterParams
      }
      const response = await getRemedialList(queryParams,{attributes})
      setRemedialList(response.remedialList)
      setPagination({
        currentPage: response.remedialList.pagination.currentPage,
        pageSize: response.remedialList.pagination.pageSize,
        totalCount: response.remedialList.pagination.totalCount,
        totalPages: response.remedialList.pagination.totalPages,
      });
    }catch(e){
      // CAPTURE EXCEPTION
      throw e
    } finally{
      remedialState.off()
    }
  }
  // VIEW TASK
  const loadTask = async (taskId) => {
    try {
      const response = await getTaskAPI(taskId);
      setCurrentViewTask(response.task);
    } catch (e) {
      throw e;
    } finally {
      //
    }
  };
  // UPDATE TASK
  const updateTask = async (taskId, updateParams) => {
    tlState.on();
    try {
      const response = await updateTaskAPI(taskId, updateParams);
      await IndexCacheService.clearCache()
      setCurrentViewTask(response.task);
    } catch (e) {
      throw e;
    } finally {
      tlState.off();
    }
  };

  // DELETE TASK
  const deleteTask = async (taskId, plantId) => {
    tlState.on();
    try {
      await deleteTaskAPI(taskId);
      await IndexCacheService.clearCache()
      await loadRemedialTasks({ plantId })
    } catch (e) {
      throw e;
    } finally {
      tlState.off();
    }
  };

  //filter applied
  const checkIsFilterApplied = () => {
    if (
      filterParams.isArchiveSelected ||
      filterParams.selectedTaskStatus.length !== 0 ||
      filterParams.selectedPlant.length !== 0 ||
      filterParams.selectedAssignedEngineer.length !== 0 ||
      filterParams.startDate ||
      filterParams.resolvedDate
    ) {
      return true;
    } else return false;
  };
  const isFilterApplied = checkIsFilterApplied();
  const subCheckIsFilterApplied = () => {
    if (
      filterParams.isArchiveSelected ||
      filterParams.selectedTaskStatus.length !==0 ||
      filterParams.selectedAssignedEngineer.length !== 0 ||
      filterParams.startDate ||
      filterParams.resolvedDate
    ) {
      return true;
    } else {
      return false;
    }
  };
  const isSubFilterApplied = subCheckIsFilterApplied();

  const loadAllRemedialTasks = async (filter) => {
    tlState.on();
    try {
      const response = await getAllRemedialtasks(filter);
      setRemedialTasks(response);
    } catch (e) {
      // HANDLE ERROR
    } finally {
      tlState.off();
    }
  };
  //ARCHIVE TASK
  const archiveTask = async (taskId) => {
    try {
      tlState.on();
      await archiveTaskAPI(taskId);
      await loadRemedialTasks()
    } catch (e) {
      throw e;
    } finally {
      tlState.off();
    }
  };

  //UNARCHIVE TASK
  const unarchiveTask = async (taskId) => {
    try {
      tlState.on();
      await unarchiveTaskAPI(taskId);
      await loadRemedialTasks()
    } catch (e) {
      throw e;
    } finally {
      tlState.off();
    }
  };

  const handleApplyFilter = (selectedFilters) => {
    setFilterParams(selectedFilters);
  };

  const resetClientListFilter = () => setFilterParams(Object.assign({}, initialFilterParams));

  const mContext = {
    remedialList,
    showFilter,
    sfState,
    isFilterApplied,
    checkIsFilterApplied,
    pagination,
    loadRemedialList,
    loadRemedialTasks,
    remedialLoading,
    filterParams,
    currentViewTask,
    isSubFilterApplied,
    subCheckIsFilterApplied,
    loadTask,
    updateTask,
    deleteTask,
    handleApplyFilter,
    resetClientListFilter,
    setRemedialList,
    remedialTasks,
    loadingTasks,
    archiveTask,
    initialFilterParams,
    unarchiveTask,
    loadAllRemedialTasks, getTableSettings,
    isLoadingFilters,
    setTasksColumnSettings,
    tasksColumnSettings,
    remedialState,
  };

  return <RemedialContext.Provider value={mContext}>{props.children}</RemedialContext.Provider>;
};

export const withRemedialProvider = (Container, containerProps) => (props) =>
(
  <RemedialProvider>
    <Container {...containerProps} {...props} />
  </RemedialProvider>
);
