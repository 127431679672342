import { CHECK_IDENTIFIER, CREATE_STRING, EXPORT_STRING, FETCH_IMPORT, MANAGE_STRING, STRING_IMPORT_URL, STRING_LIST_API, STRING_OPTIONS } from "../../../constants/endpoints";
import apiService from "../../../services/ApiService";
import IndexCacheService from "../../../services/IndexCacheService";

export const getStringList = async filterParams => {
    const response = await apiService.get(STRING_LIST_API, {
        queryParams: filterParams,
        cache:true
    })
    return response
}
export const getStringAPI = async stringId => {
    const response = await apiService.get(MANAGE_STRING, {
        pathParams: { stringId }
    })
    return response
}
export const updateStringAPI = async (stringId, params) => {
    const response = await apiService.put(MANAGE_STRING, {
        pathParams: { stringId },
        body: params
    })
    await IndexCacheService.clearCache()
    return response
}
export const deleteStringAPI = async stringId => {
    const response = await apiService.delete(MANAGE_STRING, {
        pathParams: { stringId }
    })
    await IndexCacheService.clearCache()
    return response
}
export const createStringAPI = async params => {
    const response = await apiService.post(CREATE_STRING, {
        body: params,
    })
    await IndexCacheService.clearCache()
    return response
}
export const checkIdentifier = async (identifier, plantId, stringIds) => {
    const response = await apiService.get(CHECK_IDENTIFIER, {
        queryParams: { identifier, plantId, stringIds }
    })
    return response
}

export const getOptions = async () => {
    const response = await apiService.get(STRING_OPTIONS, {
    })
    return response
}
export const exportTask = async (filterParams) => {
    const response = await apiService.get(EXPORT_STRING, {
        queryParams: filterParams,
    })
    return response
}
export const importFile = async (file, plantId) => {
    const response = await apiService.post(STRING_IMPORT_URL, {
        formData: { file, plantId }
    })
    return response
}
export const fetchImportId = async (plantId) => {
    const response = await apiService.get(FETCH_IMPORT, {
        queryParams: { plantId, category: 'STRING' }
    })
    return response
}
